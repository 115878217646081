import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Subjectevents_data from "../../Assets/JSON/MV.json";
import ImageCarosel from '../../Component/Carosel/Image_Carosel';
import { Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button } from '@mui/material';

const SubjectActivities = () => {
  const params = useParams()
  const { subname } = params
  const [departmentData, setDepartmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenDialog = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = Subjectevents_data.College_Department_Details.find(data => data.department.toLowerCase() === subname.toLowerCase());
        if (data) {
          setDepartmentData(data);
        } else {
          setError("Department data not found");
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [subname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!departmentData || !departmentData.News_Events || departmentData.News_Events.length === 0) {
    return <div>No data found for department ID: {subname}</div>;
  }
  return (
    <section className="container py-4">
    <h2 className="text-center">{departmentData.department}</h2>
    <div className="row">
      {departmentData.News_Events.map((activity, index) => (
        <div className="col-sm-4 mb-3" key={index}>
          {activity.image && (
            <div onClick={() => handleOpenDialog(activity)}>
            <ImageCarosel
              image_data={activity.image}
            />
            </div>
          )}
        </div>
      ))}
    </div>
    <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth= "md"
        // maxHeight= "10rem"
      >
        <DialogTitle>
          {selectedItem && (
            <>
              <h4>{selectedItem.title}</h4>
              <Typography component="p" sx={{ fontSize: "14px" }}>{selectedItem.description}</Typography>
            </>
          )}
        </DialogTitle>
        <DialogContent>
        {selectedItem && <ImageCarosel image_data={selectedItem.image} />
       }
          {selectedItem && <Typography component="p" sx={{ fontSize: "14px" }}>{selectedItem.description}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default SubjectActivities;
