import React from "react";
import "./Carosel.css";
import HeaderCarosel from "./Header_Carosel";

const HCarosel = () => {
    return (
      <HeaderCarosel />
    );
};
export default HCarosel
