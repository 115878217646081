import React from "react";
import "./Carosel.css";
import Slider from "react-slick";

const Image_Carosel = (props) => {
  let slider1 = [];
  let slider2 = [];
  const Image_data = props.image_data;
  console.log("image_data:", Image_data);

  return (
    <div className="Image_carosel">
       <Slider
        arrows={false}
        autoplay={true}
        dots={true}
      >
        {Array.isArray(Image_data) && Image_data.length > 0 ? (
          Image_data.map((item, index) => (
            <div key={index}>
              <img
                src={item || "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"}
                width="100%"
                height="300rem"
                style={{ objectFit: "cover" }}
                alt={`image_${index}`}
                onError={(e) => {
                  e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png";
                }}
              />
            </div>
          ))
        ) : (
          <div>
            <img
              src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
              width="100%"
              height={254}
              style={{ objectFit: "cover" }}
              alt="placeholder"
            />
          </div>
        )}
      </Slider>
    </div>
  );
};

export default Image_Carosel;
