import React, { useState } from 'react';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

const SubjectList = ({ subjects }) => {
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCardClick = (subject) => {
    setSelectedSubject(subject);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedSubject(null);
    setDialogOpen(false);
  };

  return (
    <div className="container my-5">
      <Typography variant='h5' className="text-center my-4">Subjects</Typography>
      <Grid container spacing={2}>
        {subjects.map((subject, index) => (
          <Grid key={index} item xs={12} md={3} className="px-2 my-2">
            <Card>
              <CardActionArea onClick={() => handleCardClick(subject)}>
                <CardMedia
                  component="img"
                  height="200"
                  image={subject.image === "" ? "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" : subject.image}
                  onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                  alt="No Image"
                  className="container"
                />
                <CardContent>
                  <Typography className="m-0" gutterBottom variant="subtitle1" component="h4">
                    {subject.Name}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" className="multine-ellipsis-4">
                    {subject.descerption}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle variant='h6'>{selectedSubject?.Name}</DialogTitle>
        <DialogContent>
          {/* <Typography>{selectedSubject?.descerption}</Typography> */}
      <Typography component="img"
                  height="200"
                  src={selectedSubject?.image === "" ? "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png" : selectedSubject?.image}
                  onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                  alt="No Image"
                  className="container"/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubjectList;
