import React from "react";
import { useNavigate,Link } from 'react-router-dom';
import Arrow_icon_right from '../../Assets/Icons/Arrow_Small_Right.svg';
import { Breadcrumbs, Typography } from "@mui/material";


const NACCComponent = () =>{

  const navigate = useNavigate();

    const NACCHeading = [
        {
            "ID" : 1,
            "title" : "AQAR",
            "pathlink" : null,
            "intetnalpath": "/aqar/11/aqardocuments"
        },
        {
            "ID" : 2,
            "title" : "Institutional Information for Quality Assurance (IIQA)",
            "pathlink" : "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/NAAC/IIQA/IIQA.pdf",
        },
        {
            "ID" : 3,
            "title" : "Self Study Report (SSR)",
            "pathlink" : "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/NAAC/SSR Docs/SSR Final Submitted.pdf",
        },
        {
            "ID" : 4,
            "title" : "Supporting Documents",
            "pathlink" : null,
            "intetnalpath": "/NAACExtended/11/Student"
        },
        {
            "ID" : 5,
            "title" : "Assessment Outcome Document (AOD)",
            "pathlink" : "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/NAAC/Assessment Outcome Document/TSCOGN13354 SSR.pdf",
        },
        {
            "ID" : 6,
            "title" : "Certificate of Accreditation",
            "pathlink" : "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/NAAC/NAAC Certificate - 2007.pdf",
        }
    ]

   const navigation_url = (path) => {
        if(path.pathlink == null){
            navigate(path.intetnalpath);
        }else{
            window.open(path.pathlink);
        }
    }
    return (
        <section className="container py-5">
            <Breadcrumbs separator="›" sx={{ width: "100%", display: "block",marginBottom: "10px" }} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/">
          <b>Home</b>
        </Link>
        <Typography underline="hover" color="text.primary"><b>NAAC</b></Typography>
      </Breadcrumbs>
            <div className="mb-4">
                <Typography variant="h4">NAAC Details</Typography>
            </div>
            <div>
                {NACCHeading && NACCHeading.map((items, index) => {
                    return(
                    <div className="d-flex mb-3" key={index}>
                    <img src={Arrow_icon_right} alt="Arrow Icon" />
                    <Typography variant="subtitle1" className="m-0 cursor-pointer" onClick={() => {navigation_url(items)}}> {items.title}</Typography>
                    </div>
                    )
                } )}
            </div>
        </section>
    );
}

export default NACCComponent