import React, { useState, useEffect } from 'react';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import Award_img from "../../Assets/Images/Awards1.png";

const Achived = () => {
    return (
        <section>
 <div className='container my-5'>
                <div className='text-center'>
                    <h4>Why you should choose us</h4>
                </div>
                <div className='mt-3 text-center'>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <div>
                                <img src={Award_img} />
                            </div>
                            <div className='mt-2'>
                                <h6>Top 10 in India</h6>
                                <p>Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div>
                                <img src={Award_img} />
                            </div>
                            <div className='mt-2'>
                                <h6>Top 10 in India</h6>
                                <p>Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div>
                                <img src={Award_img} />
                            </div>
                            <div className='mt-2'>
                                <h6>Top 10 in India</h6>
                                <p>Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor.</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </section>
    )
}
 export default Achived 