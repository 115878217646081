import React, { useState, useCallback } from "react";
  import Slider from "react-slick";
  import ImageViewer from "react-simple-image-viewer";
  
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";
  import "./Carosel.css";
import { Typography } from "@mui/material";
  
  const ServicesCarousel = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
  
    const BPData = {
      BP: [
        {
          title: "GYM Facility",
          designation: "President",
          image: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/GYM.JPG",
          description:
            "Institution provides gym facility for all students to keep up their health",
        },
          {
            title: "College Entrance",
            designation: "President",
            image: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/Block%20C%20Entrance%20.JPG",
            description:
              "College welcomes all types of people across various segments. Easy access is provided for differently abled people.",
          },
          {
            title: "Chemistry Lab",
            designation: "President",
            image: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/Chemistry Lab.JPG",
            description:
              "Have a good chemistry lab facility for the students to performa and learn different experiments.",
          },
          {
            title: "Computer Lab",
            designation: "President",
            image: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/COmputer Lab.JPG",
            description:
              "College provides good number of computer systems to the students to learn and practice programming.",
          },
          {
            title: "Cricket Academy",
            designation: "President",
            image: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/Criket Academy.JPG",
            description:
              "College provides good sports facilities. Has good cricket ground for the students to play along with studies.",
          },
          {
            title: "Parking Area",
            designation: "President",
            image: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/Parking area 2.JPG",
            description:
              "Good amount parking space is provided to the students and the visitors for easy access.",
          },
          {
            title: "NCC Room",
            designation: "President",
            image: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/NCC Room.JPG",
            description:
              "College has facility of NCC for the students to nurture their interest in NCC",
          },
          {
            title: "Library",
            designation: "President",
            image: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/library 2.JPG",
            description:
              "College provides good amount collection of books in the library for the students. They can enhance their knowledge with the available books.",
          },
          {
            title: "Play Ground",
            designation: "President",
            image: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/Play ground.JPG",
            description:
              "College has good play ground facility to explore their interest of games.",
          },
        ],
      };
  
      // const settings = {
      //     // dots: true,
      //     arrows: false,
      //   className: "center",
      //   centerMode: true,
      //   focusOnSelect: true,
      //   infinite: true,
      //   centerPadding: "100px",
      //   slidesToShow: 3,
      // useTransform: true,
      // useTransform: true,
      //   speed: 500,
      //   responsive: [
      //     {
      //       breakpoint: 1024,
      //       settings: {
      //         slidesToShow: 3,
      //         slidesToScroll: 3,
      //         infinite: true,
      //         // dots: true
      //       }
      //     },
      //     {
      //       breakpoint: 600,
      //       settings: {
      //         slidesToShow: 2,
      //         slidesToScroll: 2,
      //         initialSlide: 2
      //       }
      //     },
      //     {
      //       breakpoint: 480,
      //       settings: {
      //         slidesToShow: 1,
      //         slidesToScroll: 1
      //       }
      //     }
      //   ]
      // };
      
  
      // const settings = {
      //   infinite: true,
      //   speed: 500,
      //   slidesToShow: 3,
      //   slidesToScroll: 3,
      //   className: "center",
      //   centerMode: true,
      //   centerPadding: "0",
      //   responsive: [
      //     {
      //       breakpoint: 1024,
      //       settings: {
      //         slidesToShow: 3,
      //         slidesToScroll: 2,
      //         infinite: true,
      //       },
      //     },
      //     {
      //       breakpoint: 768,
      //       settings: {
      //         slidesToShow: 2,
      //         slidesToScroll: 2,
      //         infinite: true,
      //       },
      //     },
      //   ],
      // };
    
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      className: "center",
    autoplay: true,
    autoplaySpeed: 2000,
      centerMode: true,
      centerPadding: "0",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
      ],
    };
  
    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };
  
    return (
      <section className="my-5">
       <Typography variant="h4" className="mb-4 text-center"> What We Have</Typography>
        <Typography component="div" className="whStyle">
          <Slider {...settings}>
            {BPData.BP.map((items, index) => (
              <Typography component="div" key={index} className="style_daa" onClick={() => openImageViewer(index)}>
                <img
                  src={items.image}
                  className="img-fluid w-100"
                  width={354}
                  height={264}
                  alt={`Service_Picture ${index + 1}`}
                />
                <Typography component="div" className="m-4">
                  <Typography variant="h6">{items.title}</Typography>
                  <Typography component="p" variant="subtitle1">{items.description}</Typography>
                </Typography>
              </Typography>
            ))}
          </Slider>
  
          {isViewerOpen && (
            <ImageViewer
              src={BPData.BP.map((item) => item.image)}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll={true}
              style={{ minWidth: '10px', height: '10px' }}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            >
              <div>
                {isViewerOpen && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={BPData.BP[currentImage].image}
                      className="img-fluid"
                      style={{ width: '43%', height: 'auto' }}
                      alt={`images${currentImage + 1}`}
                    />
                    <div style={{ marginLeft: '16px' }}>
                      <h6>{BPData.BP[currentImage].title}</h6>
                      <p>{BPData.BP[currentImage].description}</p>
                    </div>
                  </div>
                )}
              </div>
            </ImageViewer>
          )}
        </Typography>
      </section>
    );
  };
  
  export default ServicesCarousel;
  