import React from "react";
import HCarosel from "../../Component/Carosel/Carosel";
import MissionVission from "../MV/MV";
import PlacementsCards from "../../Component/Placements_component/placementscards.js";
import Train_process_image from "../../Assets/Images/training_process.jpg";
import {Button} from "@mui/material";
import Events from "../Events/Events";
import Placements_data from "../../Assets/JSON/Placementjson/Placements.json";
import CompanyList from "../../Component/Placements_component/companylist.js";
import PlacementProcess from "../../Component/Placements_component/placementprocess.js";
// import GoalsAndObjectives from "../../Component/Placements_component/GoalsAndObjectives.js";

const placements = () => {

  return (
    <section>
      <HCarosel />
      <section className="container my-5">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PlacementsCards />
        </div>
        {/* <Yearwise */}
        <MissionVission MV_DATA={Placements_data.MV_Data} />
        {/* <GoalsAndObjectives/> */}
        <div>
          {Placements_data.Placement_data.map((items, index) => (
            <div>
            <h2>{items.aboutTrainingAndPlacementCell.title}</h2>
            <p>{items.aboutTrainingAndPlacementCell.content}</p>
            </div>
          )
          )}
          
        </div>
        <div className="Training_process">
        {Placements_data.Placement_data.map((items, index) => (
            <div>
            <h2>{items.trainingProcess.title}</h2>
            <p>{items.trainingProcess.content}</p> 
            <div>
              <img className="Training_img" alt="Training Process" src={Train_process_image} />
            </div>
            </div>
          )
          )}
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#F15215", width: "15%" }}
            >
              Know More
            </Button>
          </div>
        </div>
        {/* <Events Event_DATA={Placements_data?.Events_Details?.Training_Teams?.slice(0, 4)} title={Placements_data?.Events_Details?.Title}/> */}
        {/* <div style={{ display: "block", position: "relative", marginBottom: "150px" }}>
  <CompanyList logosImages={Placements_data?.products} title={Placements_data?.products?.Title} />
</div> */}
        {/* <Events Event_DATA={Placements_data?.Training_Events?.Training_Teams?.slice(0, 4)} title={Placements_data?.Training_Events?.title}/> */}
        {/* <PlacementProcess PlacementProcess={Placements_data?.Placement_Process}/> */}
        {/* <Events Event_DATA={Placements_data?.Placement_Events?.Placement_activities?.slice(0, 4)} title={Placements_data?.Placement_Events?.title}/> */}
      </section>
    </section>
  );
};

export default placements;
