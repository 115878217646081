import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import "../../Styles/placements.css";
import Orange_Arrow from "../../Assets/Icons/arrow-right-line.svg";
import { Typography, Button } from "@mui/material";

const PlacementsCards = () => {
  const placementCards = [
    { id: "1", title: "BSC", PlacedData: "68 Students", department: "BSC" },
    { id: "2", title: "BA", PlacedData: "68 Students", department: "BA" },
    { id: "3", title: "BCOM", PlacedData: "186 Students", department: "BCOM" },
    { id: "4", title: "BBA", PlacedData: "26 Students", department: "BBA" },
  ];

  return (
    <Grid container spacing={2} className="placement_container">
      {placementCards.map((card) => (
        <Grid key={card.id} item xs={1} sm={2} md={2.5} className="placement_grid">
          <Typography variant="h5" sx={{ fontWeight: "600" }}>
            {card.title}
          </Typography>
          <Typography component="p" className="py-3">{card.PlacedData}</Typography>
          <div className="content">
          <Typography variant="body2" sx={{ color: "#F15215", border: "none", textDecoration:"none" }} component={Link} to={`/placementslist/${card.department}`}>
              View More
             <Typography component="img" variant="body2"  sx={{ width: '20px', height: '20px' }}  src={Orange_Arrow} alt="Arrow" />
            </Typography>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default PlacementsCards;
