import React from "react";
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  BrowserRouter as Router
} from "react-router-dom";
import AppRoutes from './Routes.js';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: '600',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '600',
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: '600',
      lineHeight: 1.4,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: '600',
      lineHeight: 1.5,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: '600',
      lineHeight: 1.6,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: 1.7,
    },
    subtitle1: {
      fontSize: '1rem',
      color: "#667085",
    },
    subtitle2: {
      fontSize: '0.875rem',
      color: "#667085",
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.6,
    },
    button: {
      fontSize: '1rem',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.6,
    },
    overline: {
      fontSize: '0.625rem',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
      dark: '#115293',
      light: '#4791db',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f50057',
      dark: '#c51162',
      light: '#ff4081',
      contrastText: '#fff',
    },
    error: {
      main: '#f44336',
      dark: '#d32f2f',
      light: '#e57373',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      dark: '#f57c00',
      light: '#ffb74d',
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3',
      dark: '#1976d2',
      light: '#64b5f6',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50',
      dark: '#388e3c',
      light: '#81c784',
      contrastText: '#fff',
    },
    text: {
      primary: '#333',
      secondary: '#666',
      disabled: '#999',
      hint: '#bbb',
    },
    background: {
      default: '#fff',
      paper: '#f9f9f9',
    },
    divider: '#ddd',
  },
});

function App() {
  return (
    <Router>
       <ThemeProvider theme={theme}>
        <AppRoutes />
        </ThemeProvider> 
    </Router>
  );
}

export default App;
