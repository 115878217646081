import React from "react";
import Slider from "react-slick";
import Typography from "@mui/material/Typography";
// import Arrow_Small_Left from "../../Assets/Icons/Arrow_Small_Left.svg";
// import Arrow_Small_Right from "../../Assets/Icons/Arrow_Small_Right.svg";
import "../../Styles/NoticeCarousel.css";

const NoticeCarousel = ({ data }) => {
  // const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="carousel-container">
      <Typography
              variant="h6"
              className="m-0 carousel-title"
              style={{ color: "#000", textAlign: "center" }}
              gutterBottom
            >
              Notice Board:
            </Typography>
      <Slider className="px-3 custom-slider" {...settings}>
        {data.map((item, index) => (
          <div key={index} className="carousel-item">
            <Typography
              variant="body2"
              className="carousel-description"
              style={{ textAlign: "center" }}
              gutterBottom
            >
              {item.description}
            </Typography>
            <div className="d-flex justify-content-center align-items-center mt-1">
              <img
                src={item.logoPath || ""}
                alt="Logo"
                className="carousel-logo"
                onError={(e) => {
                  e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png";
                }}
              />
            </div>
            {/* <div className="carousel-navigation">
              <div className="nav-icon" onClick={() => sliderRef.current.slickPrev()}>
                <img src={Arrow_Small_Left} alt="Previous" />
              </div>
              <div className="nav-icon" onClick={() => sliderRef.current.slickNext()}>
                <img src={Arrow_Small_Right} alt="Next" />
              </div>
            </div> */}
          </div>
        ))}
      </Slider>
    </div>
      
  );
};

export default NoticeCarousel;
