import React, { useState } from "react";
import { Modal, Box, Typography, IconButton, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import WelcomeData from '../Assets/JSON/MV.json';

const WelcomeModal = ({ open, onClose }) => {
  const Welcomedata = WelcomeData.HomePage_Details.Notification_Board;
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          bgcolor: "white",
          outline: "none",
          borderRadius: 4,
          maxWidth: { xs: "90vw", sm: "70vw", md: "50vw" }, // Responsive maxWidth
          width: "100%", // Added to ensure the box takes up the full width
          p: 3, // Increased padding to 3 for better spacing
          position: "relative",
          overflow: "auto",
          maxHeight: "100vh",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center content vertically and horizontally
            textAlign: "center", // Center text within the box
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "rgb(20,24,75)",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h5"
            id="welcome-modal-title"
            sx={{ mb: 3, fontWeight: 'bold', color: 'rgb(20,24,75)' }}
          >
            {Welcomedata.title}
          </Typography>
          <div style={{ width: "50%", height: "auto", marginBottom: "20px", position: "relative" }}>
            {!imageLoaded && (
              <Skeleton variant="rectangular" width="100%"  height = {"20rem"}   sx={{ borderRadius: '12px'}} />
            )}
            <LazyLoadImage
              src={Welcomedata.image}
              effect="blur"
              style={{ maxWidth: '100%', borderRadius: '12px'}}
              alt="Welcome Modal Image"
              afterLoad={() => setImageLoaded(true)}
            />
          </div>
          <Typography
            variant="body1"
            id="welcome-modal-description"
            sx={{
              fontSize: '0.9rem',
              lineHeight: '1.6',
              textAlign: 'justify',
              color: "rgb(20,24,75)",
              flex: '1',
              overflow: 'auto',
              maxHeight: '60vh',
            }}
          >
            {Welcomedata.description}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default WelcomeModal;
