import React from 'react'
import ActivitiesData from "../../Component/Activities_Components/Activities"
import Json_Data from "../../Assets/JSON/MV.json"

const Departmental_Activity = () => {
  return (
    <div>
      <ActivitiesData data={Json_Data.HomePage_Details.Events_Activities} title={"Departmental Activities"}/>
    </div>
  )
}

export default Departmental_Activity