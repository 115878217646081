import React from 'react'
import NccActivities from '../../Component/Activities_Components/Activities'
import Json_Data from '../../Assets/JSON/ActivitiesJson/Activities.json';

const NSS = () => {
  return (
    <div>
      <NccActivities data={Json_Data.NCCEvents_Activities}  title={"NCC Activities"} />
    </div>
  )
}

export default NSS