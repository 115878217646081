import React, { useState } from "react";
import Slider from "react-slick";
import {
  Card,
  Typography,
  CardActionArea,
  Dialog,
  DialogContent,
  IconButton,
  Button,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClearIcon from "@mui/icons-material/Clear";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const TestimonialVideo = ({ videos, title }) => {
  const [open, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({});

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleClick = (video) => {
    setCurrentVideo(video);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section className="container mt-4">
      <Typography variant="h4" className="text-center py-4">
        {title}
      </Typography>
      <Slider {...sliderSettings}>
        {videos?.map((video, index) => (
          <div key={index} style={{ padding: 0, margin: "0 4px" }}>
            <Card
              sx={{
                borderRadius: "10px",
                maxWidth: 245,
                backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent)",
              }}
            >
              <CardActionArea>
                <div style={{ position: "relative" }}>
                  <img
                    src={video.thumbnailImage}
                    alt={video.name}
                    style={{ width: "100%", height: "340px", objectFit: "cover", cursor:"auto" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: "30px",
                      backdropFilter: "blur(10px)",
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        bottom: "15%",
                        right: 1,
                        transform: "translateX(-20%)",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        borderRadius: "50%",
                    }}
                      onClick={() => handleClick(video)}
                      aria-label="Play video"
                    >
                      <PlayArrowIcon sx={{ fontSize: 25 }} />
                    </IconButton>
                  </div>
                </div>
              </CardActionArea>
            </Card>
          </div>
        ))}
      </Slider>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent sx={{ padding: 0 }}>
          <div style={{ position: "relative" }}>
            <Button
              onClick={handleClose}
              sx={{
                position: "absolute",
                zIndex: 1,
                right: 1,
                color: "white",
                backgroundColor: "rgba(82,82,79,0.7)",
                borderRadius: "0px 0px 0px 50px",
                width: "1%",
                height: "10%",
              }}
            >
              <ClearIcon />
            </Button>
            <iframe
              width="100%"
              height="600"
              src={currentVideo.videoUrl}
              title="Video player"
              frameBorder="0"
              allowFullScreen
              style={{ objectFit: "cover" }}
            ></iframe>
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default TestimonialVideo;
