import React from "react";
import { Typography } from '@mui/material';

const IQAC_Parameter = () => {

    const IQAC_Parameter_data = {
        PARAMETERS:
        {
            title: "Objective",
            description: [
                {
                    data: "The primary aim of the IQAC is to develop a system for conscious, consistent and catalytic action to improve the academic and administrative performance of the institution."
                }
            ]
        },
        Quality_Education: {
            title: "Functions:",
            description: [
                {
                    data: "Development and application of quality benchmarks / parameters for the  various academic and administrative activities of the institution."
                },
                {
                    data: "Dissemination of information on the various quality parameters of higher education."
                },
                {
                    data: "Organization of workshops, seminars on quality related themes and promotion of quality circles."
                },
                {
                    data: "Documentation of the various programmes / activities leading to quality improvement."
                },
                {
                    data: "Preparation of the Annual Quality Assurance Report (AQAR) to be submitted to NAAC based on the quality parameters."
                },
                {
                    data: "Organizing orientation programmes for Staff and Administrative Staff."
                },
                {
                    data: "Springboard – A Leadership Programme for Quality Circle members."
                },
                {
                    data: "Administering Feedback Mechanism for the College."
                },
                {
                    data: "Dissemination of information through national Survey on Higher Education All India Survey on Higher Education (AISHE) initiated by the Ministry of Human Resource Development."
                }
            ]

        },
        Academic_Infrastructure: {
            title: "Strategies:",
            subtitle: "IQAC shall evolve mechanisms and procedures for:",
            subtitle2: "IQAC will facilitate / contribute:",
            description1: [
                {
                    data: "Ensuring timely, efficient and progressive performance of academic, administrative and financial tasks."
                },
                {
                    data: "Optimization and integration of modern methods of teaching, learning and evaluation."
                },
                {
                    data: "Ensuring the adequacy, maintenance and functioning of the support structure."
                },
            ],
                description2: [      
                {
                    data: "To a heightened level of clarity and focus in institutional functioning towards quality enhancement and facilitate internalization of the quality culture."
                },
                {
                    data: "To the enhancement and integration among the various activities of the institution and institutionalize many good practices."
                },
                {
                    data: "To provide a sound basis for decision-making to improve institutional functioning."
                },
                {
                    data: "To act as a change agent in the institution."
                },
                {
                    data: "To better inter communication."
                },
            ]

        },
        // Related_Activities: {
        //     title: "C. Placements / Industry-Related Activities",
        //     description: [
        //         {
        //             data: "T & P Cell to provide campus recruitment training"
        //         },
        //         {
        //             data: "T & P team interfaces with industry"
        //         },
        //         {
        //             data: "Career guidance cell exposes and explores varied career & higher education opportunities"
        //         },
        //         {
        //             data: "Campus recruitment training honing employability skills"
        //         },
        //         {
        //             data: "Mission R&D develop and support promising student’s careers in product development"
        //         },
        //         {
        //             data: "Guest lectures by industrial experts expose the students to knowledge beyond the curriculum"
        //         },
        //         {
        //             data: "Industrial visits and tours aim at practical exposure to organizations"
        //         },
        //     ]

        // },
        // Research_Development: {
        //     title: "D. Research & Development",
        //     description: [
        //         {
        //             data: "Faculty publications aim to disseminate one’s research"
        //         },
        //         {
        //             data: "Student publications aid encourage students for further research"
        //         },
        //         {
        //             data: "Researching through sponsored and sanctioned projects"
        //         },
        //         {
        //             data: "Exposure to cutting edge technologies and the latest developments through international conferences"
        //         },
        //         {
        //             data: "Problem solving and revenue generation by extending consultancy services"
        //         },
        //         {
        //             data: "Innovation, Incubation and Entrepreneurship cell to encourage enterprising activities"
        //         },
        //         {
        //             data: "Faculty deputed to Workshops/Seminars/Conferences for up-gradation of knowledge"
        //         }
        //     ]

        // }

    }
    return (
        <section className="container py-4">
            <div>
                <Typography variant="h6" component="div" sx={{ my: 2 }}>
                    <b>{IQAC_Parameter_data.PARAMETERS.title}</b>
                </Typography>
                <div>
                    <ul>
                        {IQAC_Parameter_data.PARAMETERS.description.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        <b>{IQAC_Parameter_data.Quality_Education.title}</b>
                    </Typography>
                </div>
                <div>
                    <ul>
                        {IQAC_Parameter_data.Quality_Education.description.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        <b>{IQAC_Parameter_data.Academic_Infrastructure.title}</b>
                    </Typography>
                </div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        <b>{IQAC_Parameter_data.Academic_Infrastructure.subtitle}</b>
                    </Typography>
                    <ul>
                    
                        {IQAC_Parameter_data.Academic_Infrastructure.description1.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        <b>{IQAC_Parameter_data.Academic_Infrastructure.subtitle2}</b>
                    </Typography>
                    <ul>
                    
                        {IQAC_Parameter_data.Academic_Infrastructure.description2.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            {/* <div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        {IQAC_Parameter_data.Related_Activities.title}
                    </Typography>
                </div>
                <div>
                    <ul>
                        {IQAC_Parameter_data.Related_Activities.description.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        {IQAC_Parameter_data.Research_Development.title}
                    </Typography>
                </div>
                <div>
                    <ul>
                        {IQAC_Parameter_data.Research_Development.description.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div> */}
        </section>
    )
}

export default IQAC_Parameter