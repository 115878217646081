import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Department_icon from '../../Assets/Icons/Department.svg';
import { Arrow_icon_around_right } from '../../Component/Image_utilites';
import image from "../../Assets/Images/Rectangle 10.png";
import Award_img from "../../Assets/Images/Awards1.png";
import "../../Styles/Course.css";
import Facilities_Carouel from '../../Component/Carosel/Facilities_Carouel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Achived from '../../Component/Achived_componet/Achived';

const Course = () => {

    const [selected_data, setselected] = useState({})

    const Syllabus_data = {
        "years" : [
            {
                "yaers_name" : "Year 1",
                "semister" :[
                    {
                        "title": "Semister 1",
                        "Syllabus_destails" : [
                            {
                                "name": " Subject Name1",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name2",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name3",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name4",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name5",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                        ]
                    },
                    {
                        "title": "Semister 2",
                        "Syllabus_destails" : [
                            {
                                "name": " Subject Name1",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name2",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name3",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name4",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name5",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                        ]
                    }
                ]
            },
            {
                "yaers_name" : "Year 2",
                "semister" :[
                    {
                        "title": "Semister 1132",
                        "Syllabus_destails" : [
                            {
                                "name": " Subject Name1",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name2",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name3",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name4",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name5",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                        ]
                    },
                    {
                        "title": "Semister 2",
                        "Syllabus_destails" : [
                            {
                                "name": " Subject Name1",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name2",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name3",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name4",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                            {
                                "name": " Subject Name5",
                                "description" : "Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper."
                            },
                        ]
                    }
                ]
            }
        ]   
    } 

    useEffect(() => { 
        setselected(Syllabus_data.years[0]);
    },[])

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
            //   <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
            //   </Box>
            )}
          </div>
        );
      }
      
      CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    const Course_Basic_data = {
        "title": "Web Application Design",
        "Basic_info": [
            {
                title_name: "Entry requirements",
                info: ''
            },
            {
                title_name: "Fee Details",
                info: ''
            }, {
                title_name: "Course duration",
                info: '2 years'
            }, {
                title_name: "Location",
                info: 'Hyderbad'
            }, {
                title_name: "Starts From",
                info: 'July 3'
            },
            {
                title_name: "Department",
                info: 'Design'
            }
        ]
    }
    const FacilitiesData = {
        Facilities: [
            {
                title: "Libary",
                image: JSON.stringify(Award_img)
            },
            {
                title: "Libary2",
                image: JSON.stringify(Award_img)
            },
            {
                title: "Libary2",
                image: JSON.stringify(Award_img)
            },
            {
                title: "Libary3",
                image: JSON.stringify(Award_img)
            }
        ]
    }
    const Fee_Stucture = [
        {
            "Tution_Fee": [

                {
                    "name": "Bachelor's Programs",
                    "value": ""
                },
                {
                    "name": "Engineering",
                    "value": "₹12,000 per year"
                },
                {
                    "name": "Business Administration",
                    "value": "₹10,500 per year"
                },
                {
                    "name": "Liberal Arts",
                    "value": "₹9,800 per year"
                }
            ],
            "Additional Costs": [
                {
                    "name": "Technology and Facilities Fee",
                    "value": "₹800 per year"
                },
                {
                    "name": "Library and Resources Fee",
                    "value": "₹300 per year"
                },
                {
                    "name": "Extracurricular Activities: ",
                    "value": "₹200 per year"
                }
            ]
        },
        {
            "Tution_Fee": [

                {
                    "name": "Bachelor's Programs",
                    "value": ""
                },
                {
                    "name": "Engineering",
                    "value": "₹12,000 per year"
                },
                {
                    "name": "Business Administration",
                    "value": "₹10,500 per year"
                },
                {
                    "name": "Liberal Arts",
                    "value": "₹9,800 per year"
                }
            ],
            "Additional Costs": [
                {
                    "name": "Technology and Facilities Fee",
                    "value": "₹800 per year"
                },
                {
                    "name": "Library and Resources Fee",
                    "value": "₹300 per year"
                },
                {
                    "name": "Extracurricular Activities: ",
                    "value": "₹200 per year"
                }
            ]
        }]
    const placement_img = [
        { image_path: "../assenats/jpg" },
        { image_path: "../assenats/jpg" },
        { image_path: "../assenats/jpg" },
        { image_path: "../assenats/jpg" },
        { image_path: "../assenats/jpg" },
        { image_path: "../assenats/jpg" },
        { image_path: "../assenats/jpg" },
        { image_path: "../assenats/jpg" },
        { image_path: "../assenats/jpg" },
    ]
   
    const Course_basic_card = ["Entry requirements", "Syllabus", "Fee details", "Sports and culture", "Job Placements"]
    return (
        <section>
            <div className='container'>
                <div>
                    <h4>{Course_Basic_data.title}</h4>
                </div>
                <Grid container spacing={4} className='mt-3 d-flex align-items-center'>
                    <Grid xs={12} md={8} item>
                        <Grid container spacing={4}>
                            {Course_Basic_data && Course_Basic_data.Basic_info.map((items, index) => {
                                return (
                                    <Grid item sm={12} md={6} className="course_infocard" key={index}>
                                        <div className='d-flex'>
                                            <div className='me-2'>
                                                <img src={Department_icon} height="25px" />
                                            </div>
                                            <div>
                                                <p className='m-0'>{items.title_name}</p>
                                                {items.info == '' ? <small className='fs-10 bule_color'>Check Details</small> : <small className='bule_color fs-10'>{items.info}</small>}
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <div className='d-flex py-3 justify-content-around align-items-center Course_card_left'>
                            <div className='text-center text-white'>
                                <small className='m-0'>Apply online</small><br />
                                <img src={Arrow_icon_around_right} />
                            </div>
                            <div className=''>
                                <img src={image} width={150} height={110} />
                            </div>
                        </div>
                        <div className='d-flex mt-3 py-3 justify-content-around align-items-center Course_card_left'>
                            <div className='text-center text-white'>
                                <small className='m-0'>Apply online</small><br />
                                <img src={Arrow_icon_around_right} />
                            </div>
                            <div className=''>
                                <img src={image} width={150} height={110} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className='container mt-5'>
                <Card>
                    <CardActionArea>
                        <CardContent className='d-flex justify-content-center'>
                            {Course_basic_card && Course_basic_card.map((basic_info, index) => {
                                return (
                                    <Typography key={index} className='Course_info_card_content px-5 text-center'>
                                        {basic_info}
                                    </Typography>
                                )
                            })}

                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
           <div>
            <Achived />
           </div>
            <div className='container mt-5'>
                <h4 className='text-center'>Entry requirements</h4>
                <div className='mt-3 text-justify'>
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper. Pretium elementum enim ut lacinia bibendum nunc pretium nunc. Venenatis integer leo nibh habitasse proin phasellus cursus. Elit vitae dui quis nam fermentum etiam sed non diam. Morbi egestas eget nec ut vitae risus gravida id.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper. Pretium elementum enim ut lacinia bibendum nunc pretium nunc. Venenatis integer leo nibh habitasse proin phasellus cursus. Elit vitae dui quis nam fermentum etiam sed non diam. Morbi egestas eget nec ut vitae risus gravida id.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Tempus erat consectetur ac tortor. Praesent diam pellentesque eu nunc nisl scelerisque. Praesent sagittis quam rutrum mi ipsum. Dui dui sed lectus tempor at porttitor lobortis suspendisse. Urna turpis vestibulum amet ultricies auctor adipiscing diam semper. Pretium elementum enim ut lacinia bibendum nunc pretium nunc. Venenatis integer leo nibh habitasse proin phasellus cursus. Elit vitae dui quis nam fermentum etiam sed non diam. Morbi egestas eget nec ut vitae risus gravida id.
                    </p>
                </div>
            </div>
            <div className='container my-5'>
                <div className='text-center'>
                    <h2>Syllabus</h2>
                </div>
                <div>
               <Tabs className='tab_header_center' value={value} onChange={handleChange} aria-label="basic tabs example">
                {Syllabus_data.years.map((items, index) => {
                    return(
                        <Tab label={items.yaers_name} onClick={(e) => {setselected(items)}} {...a11yProps(index)} />
                        )
                })}
        
        </Tabs>
      <CustomTabPanel value={value} index={value}>
        {selected_data && selected_data?.semister?.map(semister_data => {
            return(
                <React.Fragment>
                <h4 className='my-3'>{semister_data.title}</h4>
                {semister_data.Syllabus_destails.map((item, index) => {
                    return(
                        <Accordion className='my-2'>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id={index}
                          className="py-2 background-blue text-white"
                        >
                          <Typography>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {item.description}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                   )
                })}
                </React.Fragment>
            )
        })}
      </CustomTabPanel>
                </div>
                <div>

                </div>
                <div className='container mt-2'>
                    <div>
                        <h2>College Facilities</h2>
                    </div>
                    <Facilities_Carouel Facilitiesdata={FacilitiesData} />
                </div>
            </div>
            <div className='container my-5'>
                <div className='my-5 text-center'>
                    <h2>College Fee Structure for Academic Year 2023-202</h2>
                </div>
                <div>
                    <Card className='py-2'>
                        <Grid container className='d-flex justify-content-center'>
                            {Fee_Stucture && Fee_Stucture.map((items, index) => {
                                return (
                                    <Grid xs={12} md={4}>
                                        <ul>
                                            <h4 className='my-3'>Tution Fee</h4>
                                            {items.Tution_Fee.map((item) => {
                                                return (
                                                    <li style={{ lineHeight: '30px', color: "#667085" }}>
                                                        {item.name}: {item.value}
                                                    </li>
                                                )
                                            })}
                                            <h4 className='my-3'>
                                                Additional Costs
                                            </h4>
                                            {items.Tution_Fee.map(add_cost => {
                                                return (
                                                    <li style={{ lineHeight: '30px', color: "#667085" }}>
                                                        {add_cost.name}: {add_cost.value}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </Grid>
                                )
                            })
                            }

                        </Grid>
                    </Card>
                </div>
            </div>
            <div>
                <div className='text-center my-5'>
                    <h4>Placements</h4>
                </div>
                <div>
                    <Card className='px-4'>
                        <Grid container>

                            {placement_img.map((img_path, index) => {
                                return (
                                    <Grid md={2} xs={6}>
                                        <img src={Award_img} className="p-2 img-fluid" />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Card>
                </div>
            </div>
        </section>
    )
}

export default Course;