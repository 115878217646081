import React from "react";
import { Grid, Typography } from "@mui/material";
import JSONDATA from "../../Assets/JSON/MV.json";
import "../../Styles/Course_Offering.css";

const Course_Offering = () => {

  return (
    <section className="container mt-5">
      <div className="d-flex justify-content-center">
        <Typography variant="h4" className="mt-2">
          Course Offering
        </Typography>
      </div>
      <Typography variant="h5" className="m-3 course_program_heading p-2" style={{textAlign:"center"}}>
              <strong>Under Graduation Program</strong>
            </Typography>
      <Grid container spacing={2}>
        {/* Render UG courses */}
       
        {Object.keys(JSONDATA.Ug_Course).map((program, index) => (
          <Grid item xs={12} md={6} lg={4} key={index} className="my-4">
            <Typography variant="h6" className="m-3 course_offering_heading p-2">
              <strong>{program}</strong>
            </Typography>
            <div>
              <ul className="course_offering_list">
                {JSONDATA.Ug_Course[program].Ug_Data.map((course, courseIndex) => (
                  <li key={courseIndex} className="p-2 my-2 mx-3">
                    <Typography
                      variant="body1"
                      className="m-0"
                    >
                      <strong>{course.names[0]}</strong>
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        ))}

        {/* Render PG courses */}
        </Grid>
        <Typography
  variant="h5"
  className="m-3 course_program_heading p-1"
  style={{ textAlign: "center" }}
>
  <strong> Post Graduation Program </strong>
</Typography>

        <Grid container spacing={2}>

       
        {Object.keys(JSONDATA.Pg_Course).map((program, index) => (
          <Grid item xs={12} md={6} lg={4} key={index} className="my-4">
            <Typography variant="h6" className="m-3 course_offering_heading p-2">
              <strong>{program}</strong>
            </Typography>
            <div>
              <ul className="course_offering_list">
                {JSONDATA.Pg_Course[program].Pg_Data.map((course, courseIndex) => (
                  <li key={courseIndex} className="p-2 my-2 mx-3">
                    <Typography
                      variant="body1"
                      className="m-0"
                    >
                      <strong>{course.names[0]}</strong>
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default Course_Offering;
