import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';

const IQAC_Objective = () => {

    const Objective_json = [
        {
            data: "In order to make DBRAEI the first choice of students and staff, the objectives are as follows:"
        },
        {
            data: "To empower women in professional courses",
        },
        {
            data: "To achieve Academic Excellence"
        },
        {
            data: "To explore the hidden talent of the students by providing a unique learning experience"
        },
        {
            data: "To build confidence among students in analyzing complex problems and in taking decisions"
        },
        {
            data: "To inculcate the acquisition of superior knowledge working skills"
        },
        {
            data: "To develop competence among students to build, motivate and lead project teams effectively"
        },
        {
            data: "To enable the students to acquire communication skills to help in presenting any matter coherently, rationally and convincingly"
        },
        {
            data: "To adopt a co-operative approach in problem-solving"
        },
        {
            data: "To establish an interface between Institute and Industry"
        }

    ]

    return (
        <section className="container py-4">
                <Typography variant="h6" component="div" sx={{ my: 2 }}>
                    IQAC OBJECTIVES
                </Typography>
            <div>
                <ul>
                    {Objective_json && Objective_json.map(items => {
                        return(
                        <li>{items.data}</li>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}

export default IQAC_Objective