/* eslint-disable use-isnan */
import { Grid, Breadcrumbs, Typography } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowIconRight from "../../Assets/Icons/Arrow_Small_Right.svg";
import PDFIcon from "../../Assets/Icons/PDF_icon.png";
import AQARExData from "../../Assets/JSON/NAACjson/AQARExtended_Data.json";
import "../../Styles/Naac.css";

const AQAR = () => {
  const [expanded, setExpanded] = useState(0);
  const [selectedNaccData, setSelectedNaccData] = useState([]);
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const parms = useParams();
  const AQARExtended_DATA = AQARExData?.AQARExtended_Headings;
  const AQARSubheadingData = AQARExData.AQARExtended_Data;

  const handleChange = (panel, index) => (event, newExpanded) => {
    localStorage.setItem("expandedIndex", newExpanded ? panel : 1);
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      const expandedPanel = document.getElementById(`accordion-panel-${panel}`);
      if (expandedPanel) {
        expandedPanel.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    zIndex: 1000,
    maxHeight: "50vh", // Adjust the maxHeight here
    overflowY: "auto",
    width: "100%",
    justifyContent: "center",
    background: theme.palette.background.default,
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    color: theme.palette.mode === "dark" ? "#fff" : "black",
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<img src={ArrowIconRight} alt="Arrow Icon" />}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
      backgroundColor: theme.palette.mode === "dark" ? "#ffff" : "#eeee",
      borderRadius: "50%",
    },
  }));

  const filterData = useCallback(
    (selected) => {
      const filteredData = AQARSubheadingData.filter(
        (Naccdata) => Naccdata.ID === selected.ID
      );
      setSelectedNaccData(filteredData);
      navigate(
        `/aqar/${selected.ID + "/" + selected?.Subheading.replace(/\s/g, "-")}`
      );
    },
    [AQARSubheadingData]
  );

  useEffect(() => {
    if (AQARSubheadingData && AQARSubheadingData.length > 0) {
      filterData(
        parms.id === NaN || parms.name === undefined
          ? { ID: 11, Subheading: "AQAR from 2022-2023" }
          : { ID: parseInt(parms.id), Subheading: parms?.name }
      );
    }

    return () => {
      isMounted.current = false;
    };
  }, [AQARSubheadingData, filterData]);

  const handlePdfClick = (urldata) => {
    if (!urldata.pathurl.trim()) {
      toast.error("There is no file", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      window.open(urldata.pathurl);
    }
  };

  return (
    <section className="container py-5">
      <Grid container spacing={4} className="py-4">
        <Grid item xs={12} md={3}>
          {AQARExtended_DATA &&
            AQARExtended_DATA.map((items, parentIndex) => {
              const isExpanded = expanded === parentIndex;
              return (
                <Accordion
                  key={parentIndex}
                  expanded={isExpanded}
                  onChange={handleChange(parentIndex)}
                  id={`accordion-panel-${parentIndex}`}
                >
                  <AccordionSummary
                    style={{
                      backgroundColor: isExpanded
                        ? "rgb(25, 47, 89)"
                        : "#D0D0D0",
                      color: isExpanded ? "white" : "inherit",
                    }}
                    onClick={() => {
                      if (!isExpanded) {
                        const firstSubheading = items.SubHeading[0];
                        filterData(firstSubheading);
                      }
                    }}
                  >
                    <Typography>{items.Heading}</Typography>
                  </AccordionSummary>
                  {items?.SubHeading &&
                    items.SubHeading.map((subdata, i) => {
                      return (
                        <AccordionDetails
                          key={i}
                          onClick={() => filterData(subdata)}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "0.9rem",
                              margin: "auto",
                              position: "relative",
                              left: "15px",
                            },
                          }}
                          className={
                            selectedNaccData.some(
                              (data) => data.ID === subdata.ID
                            )
                              ? "selected cursor-pointer"
                              : "cursor-pointer"
                          }
                        >
                          <Typography>{subdata.Subheading}</Typography>
                        </AccordionDetails>
                      );
                    })}
                </Accordion>
              );
            })}
        </Grid>
        <Grid items xs={12} md={8} className="px-5">
          {selectedNaccData[0]?.URLs ? (
            <div>
              {selectedNaccData.map((HeadItem) =>
                Object.entries(HeadItem.URLs).map(([key, value]) => (
                  <div key={key}>
                    <Typography component="h4" variant="h5" className="py-4">
                      {value.map((items, index) => (
                        <React.Fragment key={index}>
                          {index === value.length - 1
                            ? ` AQAR Documentation from ${items.Academic_Year}`
                            : ", "}
                        </React.Fragment>
                      ))}
                    </Typography>
                    <div className="d-flex flex-wrap">
                      {value.map((urldata, index) => (
                        <div key={index} className="col-md-4 mb-4">
                          <div>
                            <img
                              src={PDFIcon}
                              onClick={() => handlePdfClick(urldata)}
                              alt="PDF Files"
                              className="cursor-pointer"
                            />
                          </div>
                          <div>
                            <Typography component="p" variant="subtitle1">
                              {urldata.filename}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : (
            <div>
              <h4>No Data Selected</h4>
            </div>
          )}
        </Grid>
      </Grid>

      <ToastContainer />
    </section>
  );
};

export default AQAR;
