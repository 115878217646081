import React, { Component } from "react";
import Slider from "react-slick";
import { Typography } from '@mui/material';
import Banner from '../../Assets/JSON/MV.json';

export default class Header_Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      autoplay: true,
      fade: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <section>
        <Slider {...settings}>
          {Banner.HomePage_Details.Banners.map((banner, index) => (
            <div key={index}>
              <Typography component="img" src={banner} height={500} sx={{objectFit:"cover"}} className="w-100" alt={`banner-${index}`} />
            </div>
          ))}
        </Slider>
      </section>
    );
  }
}

          /* <Typography component="img" sx={{objectFit: "cover"}} src={image1} height={500} className="w-100"/> */
           
            /* <div className="Header_Img_Heading"> */
            /* <h1 style={{fontSize:'40px'}}>The Secret of Getting Ahead Is <span><i>Getting Started</i></span></h1> */
            /* <Button variant="contained" className="Header_Img_Btn m-3 mx-5">Apply for Admission</Button>
            </div>
            <div className="Header_Img_gradinent"></div> */
        
          // </div>
          // <div>
          /* <Typography component="img" sx={{objectFit: "cover"}} src={image2} height={500} className="w-100"/> */
            /* <div className="">
            <div className="Header_Img_Heading">
            <h1 style={{fontSize:'40px'}}>The Secret of Getting Ahead Is <span><i>Getting Started 1</i></span></h1>
            <Button variant="contained" className="Header_Img_Btn m-3 mx-5">Apply for Admission</Button>
            </div>
            <div className="Header_Img_gradinent"></div>
          </div> */
          // </div>
          // <div>
           
          /* <Typography component="img" sx={{objectFit: "cover"}} src={image3} height={500} className="w-100"/> */
          
          // </div>
          // <div>
            /* <Typography component="img" src={image2} height={500} className="w-100"/>   */
            /* <div className="">
            <div className="Header_Img_Heading">
            <h1 style={{fontSize:'40px'}}>The Secret of Getting Ahead Is <span><i>Getting Started 2</i></span></h1>
            <Button variant="contained" className="Header_Img_Btn m-3 mx-5">Apply for Admission</Button>
            </div>
           <div className="Header_Img_gradinent"></div>
          </div> */
          // </div>
           
//         </Slider>
//         </section>
//     );
// }
// }
