import React from 'react'
import NSS from '../../Component/Activities_Components/Activities'
import Json_Data from '../../Assets/JSON/ActivitiesJson/Activities.json'
const Sports = () => {
  return (
    <div>
      <NSS data={Json_Data.SportsEvents_Activities} />
    </div>
  )
}

export default Sports