import React, { Component } from "react";
import Slider from "react-slick";
import sliderImag from "../../Assets/Images/Awards1.png";
const Facilities_Carouel = (props) => {

  const Facilities_Carouel_data = props.Facilitiesdata.Facilities;

    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3
    };

    return (
      <div className="container">
        <div className="text-center">
        <Slider {...settings}>
          {Facilities_Carouel_data && Facilities_Carouel_data.map((item,index) => {
            return (
            <div key = {index} className="">
              <div className="d-flex justify-content-center">
                <img src={sliderImag} />
              </div>
              <div className="d-block">
                <p>{item.title}</p>
              </div>
            </div>
            )
          })}
        </Slider>
        </div>
      </div>
    );
}
export default  Facilities_Carouel