import React from "react";
import MVComponent from "../../Component/Missionvission/Mission";
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';

const MissionVission = ({ MV_DATA }) => {
  return (
    <section className="my-5">
      <Container>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            style={{ borderRight: "1px solid #D9D9D9", minHeight: '400px' }}
          >
            {MV_DATA?.Vission && (
              <MVComponent getdata={MV_DATA?.Vission} />
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{ minHeight: '400px' }}>
            {MV_DATA?.Mission && (
              <MVComponent getdata={MV_DATA?.Mission} />
            )}
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default MissionVission;
