import React from "react";
import "../Styles/fstyle.css";
import logo from "../Assets/Logo/logo.png";
import logo_name1 from "../Assets/Logo/college_name_1.png";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import JSONDATA from "../Assets/JSON/MV.json";

const Footer = () => {

    return (
        <section className="background-light">
            <div className="container py-3">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Typography component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }} className="align-items-center">
                            <img src={logo} width={60} alt="Logo" />
                            <img src={logo_name1} className="mx-1 image-size" alt="Brand Name" />
                        </Typography>
                        <Typography variant="body1" component="p" sx={{ my: 2 }}>
                            <b>DOST:11076</b>
                        </Typography>
                        <Typography variant="body2" component="p" sx={{ my: 2 }} className="fs-14">
                            Dr. BR Ambedkar Educational Institutions
                            <br />
                            AP Housing Board, Bagh Lingampally,
                            <br />
                            New Nallakunta, Hyderabad,
                            <br />
                            Telangana-500044.
                            <br />
                            Email:<a href="mailto:helpdesk@dbraei.edu.in">helpdesk@dbraei.edu.in</a>
                            <br />
                            Contact: <a href="tel:040-27670675">040 – 2767 06 75</a>/<a href="tel:+919100221282">+91 9100221282</a>
                        </Typography>
                    </Grid>
                   
                    <Grid item xs={12} md={4}>
        <Typography variant="div" sx={{ my: 4 }}>
            <Typography variant="h4" textAlign="center" sx={{ mt: 5 }}>
                Our Courses
            </Typography>
            <Grid container spacing={2}>
                {/* UG Courses */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" sx={{ my: 2 }} textAlign="center">
                        UG
                    </Typography>
                    <Typography variant="ul" sx={{ my: 2 }} display={{ listStyle: "none" }} className="fs-14 text-center">
                        {JSONDATA?.degree_courses.map((items, index) => (
                            <li className="my-1" key={index}>{items.title}</li>
                        ))}
                    </Typography>
                </Grid>
                {/* PG Courses */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" sx={{ my: 2 }} textAlign="center">
                        PG
                    </Typography>
                    <Typography variant="ul" sx={{ my: 2 }} display={{ listStyle: "none" }} className="fs-14 text-center">
                        {JSONDATA?.masters_courses.map((items, index) => (
                            <li className="my-1" key={index}>{items.title}</li>
                        ))}
                    </Typography>
                </Grid>
            </Grid>
        </Typography>
    </Grid>
                    <Grid item xs={12} md={4} className="d-flex justify-content-center">
                        <Typography variant="div" sx={{ my: 4 }}>
                            <Typography variant="h4" sx={{ my: 2 }}>
                                Know about us
                            </Typography>
                            <Typography variant="ul" sx={{ my: 2 }} display={{ listStyle: "none" }} className="fs-14">
                                <Link to='/aboutus' className="route_link text-dark"> <li className="my-1">About Us</li></Link>
                                <Link to='/contactus' className="route_link text-dark"> <li className="my-1">Contact</li></Link>
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default Footer;
