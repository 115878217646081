import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import "../../Styles/NIRF.css";
import { Typography, Button } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const NIRF = () => {
  const [docButton, setDocButton] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleViewDocument = (pathurl) => {
    console.log('Viewing the document:', pathurl);
    setDocButton(pathurl);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const data = [
    {
      title: "NIRF-2024",
      pathurl: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/NIRF/Dr. B.R. Ambedkar College20240131-.pdf"
    }
  ];

  return (
    <section className="container py-4">
      <div style={{ display: 'flex', alignItems: 'top', justifyContent: 'space-between' }}>
        <div className="list-container">
          <table className="list-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td>
                    <Button className="btns" onClick={() => handleViewDocument(item.pathurl)}> View <RemoveRedEyeIcon className="btns" style={{ fontSize : "16px"}} /> </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isLoading && <div className="Progress_Style"><CircularProgress /></div>}
       
        {!isLoading && docButton && (
          <iframe
            src={docButton}
            title="Document Viewer"
            className="document-viewer"
          ></iframe>
        )}
      </div>
    </section>
  );
};

export default NIRF;
