import React, { useState } from "react";
import Black_Arrow from "../../Assets/Icons/Black_Arrow.svg";
import HCarosel from "../../Component/Carosel/Carosel";
import ImageCarosel from "../../Component/Carosel/Image_Carosel";
import moment from "moment";
import {
  Grid,
  Typography,
  CardActionArea,
  Card,
  CardContent,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogTitle,
  CardActions,
  DialogContent,
  DialogActions,
} from "@mui/material";

const Activities_Data = ({ data, title }) => {
  const [filterData, setFilterData] = useState(data);
  const [filterDate, setFilterDate] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsDialogOpen(true);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
    setIsDialogOpen(false);
  };

  const filterDataByDate = (date) => {
    const filteredData = data.filter(
      (item) => moment(item.event_date).year().toString() === date.toString()
    );
    setFilterData(filteredData);
  };

  const handleChange = (event) => {
    const selectedDate = event.target.value;
    setFilterDate(selectedDate);
    filterDataByDate(selectedDate);
  };

  return (
    <section>
      <HCarosel />
      <div className="container py-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="mt-4">{title}</h4>
          </div>
          <div>
            <FormControl variant="standard" sx={{ p: 0, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Year
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={filterDate}
                onChange={handleChange}
                label="Year"
              >
                {Array.from(new Array(10), (val, index) => 2024 - index).map(
                  (year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <Grid container className="py-4">
            {filterData.map((item, index) => (
              <Grid
                xs={12}
                md={index < 2 ? 6 : 4}
                className="my-3"
                key={index}
              >
                <Card className="ECard mx-2">
                  <CardActionArea onClick={() => openImageModal(item.image)}>
                    <ImageCarosel
                      image_data={item.image}
                      onImageClick={(image) => openImageModal(image)}
                    />
                    <CardContent>
                      <Typography
                        className="m-0"
                        gutterBottom
                        variant="h6"
                        component="div"
                      >
                        {item.title}
                      </Typography>
                      <Typography
  className="activity_description"
  variant="body2"
  color="text.secondary"
  style={{
    display: '-webkit-box',
    WebkitLineClamp: 2, // Number of lines to display before truncating
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '3.6rem', // Adjust based on your line height and font size
  }}
>
  {item.description}
</Typography>

                    </CardContent>
                    <CardActions className="d-flex align-items-center justify-content-between">
                      <Button size="small">{item.event_date}</Button>
                      <img src={Black_Arrow} alt="Arrow" />
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={closeImageModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedImage &&
            filterData.map((item, i) => {
              if (item.image === selectedImage) {
                return (
                  <div key={i}>
                    <h4>{item.title}</h4>
                    <Typography component="p" sx={{ fontSize: "14px" }}>
                      {item.description}
                    </Typography>
                  </div>
                );
              }
              return null;
            })}
        </DialogTitle>
        <DialogContent>
          {selectedImage && <ImageCarosel image_data={selectedImage} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeImageModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Activities_Data;
