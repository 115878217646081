import { Outlet, useRoutes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Layout from "./Component/Layout";
import AboutUsPage from "./Pages/Aboutus/Aboutus";
import ActivityPage from "./Pages/Activities/Departmental_Activity.js";
import ContactUsPage from "./Pages/Contactus/Contactus";
import CoursePage from "./Pages/Course/Course";
// import CourseOfferingPage from "./Pages/Course_Offering/Course_Offering";
import DepartmentPage from "./Pages/Department/Department";
import HomePage from "./Pages/Home";
import NACCComponentPage from "./Pages/NACC/NACC";
import NACCExtendedPage from "./Pages/NACC/NACCExtended";
import AQARPage from "./Pages/NACC/AQAR.js";
import FeedbackPage from "./Pages/Feedback/Feedback";
import PlacementsPage from "./Pages/Placements/placements";
import AboutIQACPage from "./Pages/IQAC/AboutIQAC";
import IQACObjectivePage from "./Pages/IQAC/IQAC_Objective";
import IQACParameterPage from "./Pages/IQAC/IQAC_Parameters";
import QualityPolicyPage from "./Pages/IQAC/Quality_Policy";
import MandatoryDocPage from "./Pages/IQAC/Mandatory_doc";
import WorkingCommitteePage from "./Pages/IQAC/Working_Commitee";
import Academiccalender from "./Pages/IQAC/academiccalender.js";
import InfrastructurePage from "./Pages/Aboutus/Infrastructure";
import PlacementsCards from "./Component/Placements_component/placementscards.js";
import PlacementsList from "./Pages/Placements/Studentsplacedlist.js";
import Error404 from "./Error404";
import SocialBar from './Pages/Socialbar/Socialbar.js';
import SubjectActivities from "./Pages/Department/SubjectActivities";
import NIRF from "./Pages/NIRF/NIRF.js";
import PlantInfoPage from "./Pages/Department/PlantInfoPage.js"
import NCC from "./Pages/Activities/NCC.js";
import NSS from "./Pages/Activities/NSS.js";
import SAC from "./Pages/Activities/SAC.js";
import Sports from "./Pages/Activities/Sports.js";
import "./App.css";

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const routesNav = [
    { element: <HomePage />, path: "/" },
    { element: <HomePage />, path: "/home" },
    { element: <DepartmentPage />, path: "/department/:name/:subname" },
    { element: <CoursePage />, path: "/course" },
    { element: <AboutUsPage />, path: "/aboutus" },
    { element: <ActivityPage />, path: "/activity" },
    { element: <ContactUsPage />, path: "/contactus" },
    { element: <NACCComponentPage />, path: "/NAAC" },
    { element: <NACCExtendedPage />, path: "/NAACExtended/:id/:name"},
    { element: <NACCExtendedPage />, path: "/NAACExtended"},
    { element: <NACCExtendedPage />, path: "/NACCExtended/:id/:name" },
    { element: <NACCExtendedPage />, path: "/NACCExtended" },
    { element: <NIRF />, path: "/nirf" },
    { element: <AboutIQACPage />, path: "/aboutIQAC" },
    { element: <IQACObjectivePage />, path: "/IQACObjective" },
    { element: <IQACParameterPage />, path: "/IQACParameter" },
    { element: <QualityPolicyPage />, path: "/Qualitypolicy" },
    { element: <MandatoryDocPage />, path: "/mandatorydoc" },
    { element: <WorkingCommitteePage />, path: "/workingcommitee" },
    { element: <Academiccalender/>, path: "/academic-calendars" },
    { element: <FeedbackPage />, path: "/feedback" },
    { element: <InfrastructurePage />, path: "/infrastructure" },
    { element: <PlacementsPage />, path: "/placements" },
    { element: <AQARPage />, path: "/aqar/:id/:subname" },
    { element: <SubjectActivities/>, path: "/department/:name/:subname/subject-activities" },
    { element: <PlantInfoPage/> ,  path: "/department/:name/:subname/plant-details/:ID/:SubHeading" },

    { element: <NCC />, path: "/ncc" },
    { element: <NSS />, path: "/nss" },
    { element: <SAC />, path: "/sac" },
    { element: <Sports />, path: "/sports"},
    { element: <PlacementsCards />, path: "/placementslist" },
    { element: <PlacementsList />, path: "/placementslist/:department" },
    { element: <Error404 />, path: "*" }
  ];

  const routesData = [
    {
      element: <Layout />,
      children: [
        {
          element: <Outlet />,
          children: routesNav,
        },
      ],
    },
  ];

  return (
    <div>
      {useRoutes(routesData)}
      <SocialBar />
    </div>
  );
};

export default AppRoutes;
