import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';

const Quality_Policy = () => {
    return (
        <section className="container py-4">
                <Typography variant="h6"
              component="div" sx={{ my: 2 }}>
                    QUALITY POLICY
                </Typography>
            <div>
                <Typography variant="p"
              component="div" sx={{ flexGrow: 1, fs:'14px', display: { xs: 'none', sm: 'flex' } }}>
                    DBRAC is committed to delivering Quality Education for all-round development of the students to meet the changing requirements of industry and society. With excellence in education through continual improvements, DBRAC is committed to providing responsible technocrats for effective nation-building through imparting quality education and training. The college also develops students with a disciplined and integrated personality, facilitating faculty and supports staff to update their knowledge and skills to match the industrial and technological development.
                </Typography>
            </div>
        </section>
    )
}

export default Quality_Policy