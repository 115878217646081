import React, { useEffect, useState, useRef, useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowIconRight from "../../Assets/Icons/Arrow_Small_Right.svg";
import PDFIcon from "../../Assets/Icons/document_icon.svg";
import IQAC_Data from "../../Assets/JSON/IQACjson/acalendar.json";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../../Styles/Naac.css";


const Academiccalender = () => {
  const [expanded, setExpanded] = useState(0);
  const [selectedNaccData, setSelectedNaccData] = useState([]);
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const parms = useParams();
  const IQACExtended_DATA = IQAC_Data?.dOutcome_Headings;
  const IQACSubheadingData = IQAC_Data.dOutcomeExtended_Data;

  const handleChange = (panel, index) => (event, newExpanded) => {
    localStorage.setItem("expandedIndex", newExpanded ? panel : 1);
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      const expandedPanel = document.getElementById(`accordion-panel-${panel}`);
      if (expandedPanel) {
        expandedPanel.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    zIndex: 1000,
    maxHeight: "50vh", // Adjust the maxHeight here
    overflowY: "auto",
    width: "100%",
    justifyContent: "center",
    background: theme.palette.background.default,
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    color: theme.palette.mode === "dark" ? "#fff" : "black",
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<img src={ArrowIconRight} alt="Arrow Icon" />}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
      backgroundColor: theme.palette.mode === "dark" ? "#ffff" : "#eeee",
      borderRadius: "50%",
    },
  }));

  const filterData = useCallback(
    (selected) => {
      const filteredData = IQACSubheadingData.filter(
        (Naccdata) => Naccdata.ID === selected.ID
      );
      setSelectedNaccData(filteredData);
      navigate(`/academic-calendars`
      );
    },
    [IQACSubheadingData]
  );

  useEffect(() => {
    if (IQACSubheadingData && IQACSubheadingData.length > 0) {
      filterData({ ID: 11, Subheading: "Programme Outcomes / Course Outcomes" });
    }

    return () => {
      isMounted.current = false;
    };
  }, [IQACSubheadingData, filterData]);

  const handlePdfClick = (urldata) => {
    if (!urldata.pathurl.trim()) {
      toast.error("There is no file", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      window.open(urldata.pathurl);
    }
  };
  return (
    <section className="container my-5 py-5">
      {/* <div className="my-4">
        <div>
          <Typography variant="h6">
            {IQAC_Data.aCalander1.title}
          </Typography>
        </div>
        {IQAC_Data.aCalander1.aCalander1_data.map((items) => {
          return (
            <div>
              <Typography
                variant="subtitle2"
                className="cursor-pointer mx-4 text-decoration"
                onClick={() => window.open(items.path)}
              >
                {items.title}
              </Typography>
            </div>
          );
        })}
      </div> */}
      <Grid container spacing={4} className="py-5 mx-3">
        <Grid xs={10} md={4}>
          {IQACExtended_DATA &&
            IQACExtended_DATA.map((items, parentIndex) => {
              const isExpanded = expanded === parentIndex;
              return (
                <Accordion
                  key={parentIndex}
                  expanded={isExpanded}
                  onChange={handleChange(parentIndex)}
                  id={`accordion-panel-${parentIndex}`}
                  height="100px"
                >
                  <AccordionSummary
                    style={{
                      backgroundColor: isExpanded
                        ? "rgb(25, 47, 89)"
                        : "#D0D0D0",
                      color: isExpanded ? "white" : "inherit",
                    }}
                    onClick={() => {
                      if (!isExpanded) {
                        const firstSubheading = items.SubHeading[0];
                        filterData(firstSubheading);
                      }
                    }}
                  >
                    <Typography>{items.Heading}</Typography>
                  </AccordionSummary>
                  {items?.SubHeading &&
                    items.SubHeading.map((subdata, i) => {
                      return (
                        <AccordionDetails
                          key={i}
                          onClick={() => filterData(subdata)}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "0.9rem",
                              position: "relative",
                              left: "15px",
                            },
                          }}
                          className={
                            selectedNaccData.some(
                              (data) => data.ID === subdata.ID
                            )
                              ? "selected cursor-pointer"
                              : "cursor-pointer"
                          }
                        >
                          <Typography>{subdata.Subheading}</Typography>
                        </AccordionDetails>
                      );
                    })}
                </Accordion>
              );
            })}
        </Grid>
        <Grid items xs={12} md={8} className="px-5">
          {selectedNaccData[0]?.URLs ? (
            <div>
              {selectedNaccData.map((HeadItem) =>
                Object.entries(HeadItem.URLs).map(([key, value]) => (
                  <div key={key}>
                    <Typography component="h4" variant="h5" className="py-4">
                      {value.map((items, index) => (
                        <React.Fragment key={index}>
                          {index === value.length - 1
                            ?  items.Title
                            : ", "}
                        </React.Fragment>
                      ))}
                    </Typography>
                    <div className="d-flex flex-wrap">
                      {value.map((urldata, index) => (
                        <div key={index} className="col-md-4 mb-4">
                          <div>
                            <img
                              src={PDFIcon}
                              onClick={() => handlePdfClick(urldata)}
                              alt="PDF Files"
                              className="cursor-pointer"
                            />
                          </div>
                          <div>
                            <Typography component="p" variant="subtitle1">
                              {urldata.filename}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : (
            <div>
              <h4>No Data Selected</h4>
            </div>
          )}
        </Grid>
      </Grid>

      <ToastContainer />

    </section>
  );
};

export default Academiccalender;
