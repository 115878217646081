import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Slide,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../Assets/Logo/logo.png";
import phone_black_icon from "../Assets/Icons/phone_black.svg";
import JSONDATA from "../Assets/JSON/MV.json";
import "../Styles/Style.css";
import FounderImage from "../Assets/Images/Kaka-venkataswamy-2-768x538 1.png";
import logo_name1 from "../Assets/Logo/college_name_1.png";

const drawerWidth = 300;

const navItems = [
  { id: 1, title: "Home", pathname: "/home" },
  { id: 2, title: "About", pathname: "/about", subnav_flag: true, subnav: [{ title: "AboutUs", pathname: "/aboutus" }, { title: "InfraStructure", pathname: "/infrastructure" }] },
  { id: 3, title: "Department", pathname: "/department" },
  { id: 4, title: "Activities", pathname: "/activity", subnav_flag: true, subnav: [{ title: "Departmental activities", pathname: "/activity" }, { title: "NCC", pathname: "/ncc" }, { title: "NSS", pathname: "/nss" }, { title: "Sports", pathname: "/sports" }, { title: "SAC", pathname: "/sac" }] },
  { id: 5, title: "IQAC", pathname: "/iqac", subnav_flag: true, subnav: [{ title: "About IQAC", pathname: "/aboutIQAC" }, { title: "Quality Policy", pathname: "/Qualitypolicy" }, { title: "IQAC Objectives", pathname: "/IQACObjective" }, { title: "Parameters of IQAC", pathname: "/IQACParameter" }, { title: "Working Committee", pathname: "/workingcommitee" }, { title: "Manatory Document", pathname: "/mandatorydoc" }, { title: "Acadamic calendar and outcome", pathname: "/academic-calendars" }] },
  { id: 6, title: "NIRF", pathname: "/NIRF" },
  { id: 7, title: "NAAC", pathname: "/NAAC" },
  { id: 8, title: "Contact us", pathname: "/contactus" },
];

const subnavItems = [
  { id: 1, title: "Online Fee Payment", inactive: false },
  { id: 2, title: "Placements", pathname: "/placements", inactive: false, subnav: [{ title: "overview", pathname: "/placements", inactive: false }, { title: "Training", pathname: "/feedback", inactive: false }] },
  { id: 3, title: "Feedback", pathname: "/feedback", inactive: false },
  { id: 4, title: "Student", pathname: "/home", inactive: true },
  { id: 5, title: "Staff", pathname: "/home", inactive: true },
];

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const navigateToRoute = (item) => {
    if (item.title === "Department") {
      handleDialogOpen();
    } else if (item.subnav_flag) {
      setSelectedMenuItem(item.id);
    } else {
      navigate(item.pathname);
      setSelectedMenuItem(null);
    }
  };

  const isMenuItemSelected = (item) => {
    if (location.pathname === item.pathname) return true;
    if (item.subnav) {
      return item.subnav.some(subItem => location.pathname.includes(subItem.pathname));
    }
    return false;
  };

  const navigateToSubRoute = (data, subData) => {
    handleDialogClose();
    const selectedData = { College: data, Department: subData };
    navigate(`/department/${data.title.toLowerCase().replace(/ /g, "-")}/${subData.title.toLowerCase().replace(/ /g, "-")}`, { state: { College_details: selectedData } });
  };

  const NaacImage = "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Notice board/a-grade-1-removebg.png";

  return (
    <section className="Hbox">
      <React.Fragment>
      
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            "& .MuiDrawer-paper": { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <div className="text-start px-3 py-1">
            <Typography variant="body2" onClick={handleDrawerToggle} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </Typography>
          </div>
          <div style={{ width: drawerWidth }}>
            <List>
              {navItems.map((item) => (
                <ListItem button key={item.id}  selected={isMenuItemSelected(item)} onClick={() => navigateToRoute(item)}>
                  <ListItemText className="pl-2 heading_bold" primary={item.title} />
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              {subnavItems.map((item) => (
                <ListItem button key={item.id} selected={isMenuItemSelected(item)} onClick={() => navigateToRoute(item)} disabled={item.inactive}>
                  <ListItemText primary={item.title} className="pl-2 heading_bold" />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>

        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={handleDialogClose}
          TransitionComponent={Slide}
          TransitionProps={{ direction: "up" }}
          scroll="paper"
          className="Header_Dialog"
          sx={{ "& .MuiDialog-paper": { maxWidth: "100%", top: "20%" } }}
        >
          <div className="text-end py-3 px-5">
            <Typography variant="body1" onClick={handleDialogClose} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </Typography>
          </div>
          <Grid container spacing={2} className="px-5 py-5" sx={{ display: "relative", width: "100%" }}>
            {JSONDATA.sub_heading?.map((item, index) => (
              <Grid item xs={12} md={4} key={index} className="my-1 cart_nav text-center justify-content-center">
                <div style={{ textAlign: "center" }}>
                  <Typography variant="h4" style={{ margin: "0.5rem" }}>{item.title}</Typography>
                  {item.subdeparment?.map((subItem, subIndex) => (
                    <div style={{ textAlign: "center" }} key={subIndex}>
                      <Typography variant="body2" className="m-0 heading_bold cursor-pointer" onClick={() => navigateToSubRoute(item, subItem)}>
                        <p style={{ marginBottom: "0rem" }}>{subItem.title === "BCOM General" ? subItem.subtitle : subItem.title}</p>
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid>
            ))}
          </Grid>
        </Dialog>
      </React.Fragment>

      <CssBaseline />
      <AppBar component="nav" color="inherit" className="box-shadow_soft" id="nav">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-sm-5" id="Header">
              <div className="col-sm-2">
                <img className="col-m-7" style={{ padding: "5px", objectFit: "cover", maxWidth: "100px" }} src={FounderImage} alt="Founder" />
                <div>
                  <Typography variant="body1" component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }} className="pt-2 justify-content-between">
                    <div>
                      <img src={phone_black_icon} alt="Phone Icon" />
                      <small className="mx-1"><a href="tel:040-27670675">040 – 2767 06 75</a>/<a href="tel:+919100221282">+91 9100221282</a></small>
                    </div>
                    <div>
                      <small className="heading_bold" >DOST:11076</small>
                    </div>
                  </Typography>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }} className="py-2 align-items-center">
                    <img src={logo} width={60} alt="Logo" />
                    <img src={logo_name1} className="mx-1 image-size" alt="Brand Name" />
                    <img style={{ width: "auto", justifyContent: "center", height: "90px" }} src={NaacImage} alt="naac A+" />
                  </Typography>
                </div>
              </div>
            </div>
            <div className="col-sm-7 text-end pt-4">
              {isMobile ? (
                <IconButton color="inherit" aria-label="open drawer" sx={{ display: { lg: "none" }, position: "absolute" }} edge="end" onClick={handleDrawerToggle}>
                  <MenuIcon />
                </IconButton>
              ) : (
                <Box sx={{ display: { xs: "none", lg: "block" }}}>
                  {subnavItems.map((item) => (
                    <Button
                      key={item.id}
                      disabled={item.inactive}
                      onClick={() => navigateToRoute(item)}
                      sx={{
                        textTransform: "none",
                        color: item.title === "Online Fee Payment" ? "white" : "#333333",
                        backgroundColor: item.title === "Online Fee Payment" ? "#D32F2F" : "transparent",
                        fontSize: "14px",
                        padding: item.title === "Online Fee Payment" ? "5px 5px 10px 10px" : "0px 5px 10px 10px",
                        marginBottom: "10px",
                        "&:hover": {
                          backgroundColor: item.title === "Online Fee Payment" ? "#B71C1C" : "transparent",
                        }
                      }}
                      className="selected-item"
                    >
                       {item.title === "Online Fee Payment" ? (
        <a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=927272" target="_blank" rel="noopener noreferrer" style={{ color: "white", textDecoration: "none" }}>
          <Typography target="_blank" variant="body2">{item.title}</Typography>
        </a>
      ) : (
        <Typography variant="body2">{item.title}</Typography>
      )}
    </Button>
                  ))}
                </Box>
              )}
              {!isMobile && (
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  {navItems.map((item) => (
                    <React.Fragment key={item.id}>
                      <Button
                        id={`menu-anchor-${item.id}`}
                        onClick={() => navigateToRoute(item)}
                        sx={{
                          textTransform: "none",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: isMenuItemSelected(item) ? "rgb(223,82,38)" : "#333333",
                          padding: "0px 5px 0px 10px",
                          // marginTop: "30px",
                          paddingBottom: "31px",
                          borderRadius: "0px",
                        }}
                        className="selected-item"
                      >
                        {item.title}
                      </Button>
                      {item.subnav_flag && (
                        <Menu
                          anchorEl={document.getElementById(`menu-anchor-${item.id}`)}
                          open={selectedMenuItem === item.id}
                          onClose={() => setSelectedMenuItem(null)}
                          anchorReference="anchorEl"
                          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        >
                          {item.subnav.map((subItem, subIndex) => (
                            <MenuItem
                              key={subIndex}
                              onClick={() => navigateToRoute(subItem)}
                              sx={{
                                backgroundColor: location.pathname.includes(subItem.pathname) ? "#3E9CE4" : "transparent",
                                color: location.pathname.includes(subItem.pathname) ? "white" : "inherit",
                              }}
                            >
                              <Typography variant="body2">{subItem.title}</Typography>
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </div>
          </div>
        </div>
      </AppBar>
    </section>
  );
};

export default Header;
