import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';

const MANDATORY_Doc = () => {


    const MANDATORY_Doc_data = {
        title: "MANDATORY DOCUMENT",
        description: "An Internal Quality Assurance Cell (IQAC) has been established during the academic year 2013. It aims to develop a system for conscious, consistent and catalytic action to improve academic and administrative activities. The cell imparts mechanisms and procedures for ensuring the timely, efficient and progressive performance of academic, administrative and financial tasks. It also focuses on optimization and integration of modern methods of teaching, learning and evaluation and ensuring the adequacy, maintenance and functioning of the support services.",
        doucments: [
            {
                title: "Right To Information (RTI) ACT – 2005",
                filename: "Right to Information Act-2005",
                pathname: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/rti-act-for-naac.pdf"
            },
            {
                title: "Undertaking Letter for Website IP Address – NAAC",
                filename: "Undertaking Letter for Website IP Address",
                pathname: "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/undertaking-letter-for-website-IP-address-naac.pdf"
            }
        ]
    }
    return (
        <section className="container py-4">
            <div>
                <Typography variant="h6" component="div" sx={{ my: 2 }}>{MANDATORY_Doc_data.title}</Typography>
            </div>
            <div>
                <p>{MANDATORY_Doc_data.description}</p>
            </div>
            <div>
                {MANDATORY_Doc_data.doucments.map(items => {
                    return (
                        <div className="my-4">
                            <Typography variant="h6" component="div" sx={{ my: 2 }}>{items.title}</Typography>
                            <div>
                                <small className="cursor-pointer text-decoration" onClick={() => window.open(items.pathname)}>{items.filename}</small>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default MANDATORY_Doc