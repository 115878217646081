/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from "@mui/material";
import React, {  useState } from "react";
// import CardSocialMedia from "../../Component/Cards/CardSocialMedia";
import Servicescarosel from "../../Component/Carosel/Services_Carosel";
import FacultyAchievements from "../../Component/Departments/Achivements_components/Achivements";
import SubjectList from "../../Component/Departments/Achivements_components/Subjectlist";
import Events from "../Events/Events";
import HS from "../HS/HS";
import MissionVission from "../MV/MV";
// import PeopleIcon from "../../Assets/Icons/People_icon.svg";
import HCarosel from "../../Component/Carosel/Header_Carosel";
import CardActions from "@mui/material/CardActions";
import {
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
// import Departmentcarosel from "../../Component/Carosel/Department_Carosel";
import twitterIcon from "../../Assets/Icons/Social_icon_twitter.svg";
import linkedinIcon from "../../Assets/Icons/Social_icon_linkedin.svg";
import PlantIcon1 from "../../Assets/Icons/Plant Clasification1.svg";
import Arrow_icon from "../../Assets/Icons/Black_Arrow.svg";
import PlantIcon2 from "../../Assets/Icons/Plant Clasification2.svg";
import { useNavigate, useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
import College_Data from "../../Assets/JSON/MV.json";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "../../Component/utils";

const Department = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const College_DATA = location?.state?.College_details?.Department;
  const [Selected_College_Data, setSelected_College_Data] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPB, setSelectedPB] = useState(null);

  useComponentDidMount(() => {
    Get_College_Data();
  });
  useComponentDidUpdate(() => {
    Get_College_Data();
  });
  const Get_College_Data = () => {
    let filterkey = 'null'
    if(College_DATA?.title === "BCOM Computer Applications" || College_DATA?.title === "Business Anlytics"){
      filterkey = "BCOM General";
    }else {
      filterkey = College_DATA?.title;
    }
    let selected_College_data = College_Data.College_Department_Details?.find(
      (items) => items.department === filterkey
    );
    setSelected_College_Data(selected_College_data);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = (pb) => {
    setSelectedPB(pb);
    setOpenDialog(true);
  };

  const handlePlantGridClick = () => {
    navigate(`/department/b.sc/botany/plant-details/11/Acalypha-Ostryifolia`);
  };

  return (
    <section>
      {Selected_College_Data === undefined ? (
        <div className="m-5 py-5 text-center">
          <h1>NO DATA FOUND</h1>
        </div>
      ) : (
        <div>
          <div className="position-relative">
            <div>
              <HCarosel />
            </div>
            <div className="position-absolute bottom-0 start-0 ms-4 text-white mb-4">
              <Typography
                variant="h4"
                className="fs-1"
              >
                Welcome to <br />
                {College_DATA?.title === "BCOM General"
                  ? "Department of Commerce" : `${College_DATA?.title} Department`}
              </Typography>
            </div>
          </div>

          <div>
            <div className="my-5">
              <div className="container">
                <Grid justifyContent="center" container spacing={2}>
                  {/* <Grid container center xs={12} md={8}>
                    <Grid item xs={12} md={5} className="px-3 mx-4">
                      <Card className="background-blue height-110 d-flex justify-content-center align-items-center">
                        <CardActionArea className="text-center">
                          <CardContent>
                            <img src={PeopleIcon} alt="people Icon" />
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={5} className="px-3">
                      <Card className="background-blue height-110 d-flex justify-content-center align-items-center">
                        <CardActionArea className="d-block">
                          <Typography
                            className="m-0 text-white text-center"
                            gutterBottom
                            variant="h6"
                            component="div"
                          >
                            Student Strength
                          </Typography>
                          <Typography
                            className="m-0 text-white text-center"
                            gutterBottom
                            variant="h4"
                            component="div"
                          >
                            {Selected_College_Data?.Seats_Allotted}
                          </Typography>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={5} className="px-3">
                      <Card className="background-blue height-110 d-flex justify-content-center align-items-center">
                        <CardActionArea className="d-block">
                          <Typography
                            className="m-0 text-white text-center"
                            gutterBottom
                            variant="h6"
                            component="div"
                          >
                            <span className="m-0 fs-2">
                              {Selected_College_Data?.Seats_Intake}
                            </span>
                            Student
                          </Typography>
                          <Typography
                            className="m-0 text-white text-center"
                            gutterBottom
                            variant="h6"
                            component="div"
                          >
                            every Year
                          </Typography>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid> */}
                  {/* <Grid container xs={12} md={4}>
                                        <Grid item xs={12} md={4}></Grid>
                                        <Grid item xs={12} md={8}>
                                            <Card className='background-orange height-110 d-flex justify-content-center align-items-center'>
                                                <CardActionArea className='d-block' onClick={navigateto}>
                                                    <Typography className="m-0 text-white text-center" gutterBottom variant="h6" component="div">
                                                        Check Course Details
                                                    </Typography>
                                                    <Typography className="m-0 text-white text-center" gutterBottom variant="h6" component="div">
                                                        <img className="text-white" src={Arrow_icon} />
                                                    </Typography>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </Grid> */}
                </Grid>
              </div>
            </div>
            {/* <div>
              <Departmentcarosel />
            </div> */}
            {Selected_College_Data?.MissionVission &&
              Selected_College_Data?.MissionVission?.Mission.discreption !==
                "" && (
                <div>
                  <MissionVission
                    MV_DATA={Selected_College_Data?.MissionVission}
                  />
                </div>
              )}
            {Selected_College_Data?.HOD_Message &&
              Selected_College_Data?.HOD_Message.discreption !== "" && (
                <div>
                  <HS HS_DATA={Selected_College_Data?.HOD_Message} />
                </div>
              )}
            {Selected_College_Data?.FacultyData &&
              Selected_College_Data?.FacultyData.Faculty.length > 0 && (
                <div className="container">
                  <div className="my-4 text-center">
                    <h2>{Selected_College_Data?.FacultyData.title}</h2>
                  </div>
                  <Grid container spacing={2}>
                    {Selected_College_Data?.FacultyData &&
                      Selected_College_Data?.FacultyData.Faculty.map(
                        (items) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className="px-2 my-2"
                              onClick={() => handleOpenDialog(items)}
                            >
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="200"
                                  image={
                                    items.image === ""
                                      ? "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
                                      : items.image
                                  }
                                  alt="No Image"
                                  onError={(e) =>
                                    (e.target.src =
                                      "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                                  }
                                  className="card_image_A"
                                />
                                <CardContent className="m-0 py-2 px-0">
                                  <Typography
                                    className="m-0"
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                  >
                                    {items.Name}
                                  </Typography>
                                  <Typography
                                    gutterBottom
                                    variant="p"
                                    className="text-primary"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {items.designation}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    className="multine-ellipsis-2"
                                  >
                                    {items.descerption}
                                  </Typography>
                                </CardContent>
                                <CardActions className="d-flex align-items-center justify-content-start">
                                  <img
                                    src={twitterIcon}
                                    alt=""
                                    className="me-3"
                                  />
                                  <img src={linkedinIcon} alt="" />
                                </CardActions>
                              </CardActionArea>
                            </Grid>
                          );
                        }
                      )}
                  </Grid>
                </div>
              )}
            {Selected_College_Data?.Faculty_Achievements &&
              Selected_College_Data?.Faculty_Achievements.length > 0 && (
                <FacultyAchievements
                  facultyAchievements={
                    Selected_College_Data.Faculty_Achievements
                  }
                />
              )}
            {Selected_College_Data?.Service_Data && (
              <div>
                <Servicescarosel />
              </div>
            )}
            {Selected_College_Data?.Student_Achievements &&
              Selected_College_Data?.Student_Achievements.length > 0 && (
                <div className="container">
                  <div className="my-4">
                    <h2>Student Achivement</h2>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} className="px-2 my-2">
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="250"
                          image={
                            Selected_College_Data.Student_Achievements[0]
                              ?.image === ""
                              ? "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
                              : Selected_College_Data.Student_Achievements[0]
                                  ?.image
                          }
                          alt="No Image"
                          onError={(e) =>
                            (e.target.src =
                              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                          }
                          className="card_image_A"
                        />
                        <CardContent className="m-0 py-2 px-0">
                          <Typography
                            gutterBottom
                            variant="p"
                            className="text-primary"
                            style={{ fontSize: "12px" }}
                          >
                            {
                              Selected_College_Data.Student_Achievements[0]
                                .achived_date
                            }
                          </Typography>
                          <Typography
                            className="m-0"
                            gutterBottom
                            variant="h6"
                            component="div"
                          >
                            {
                              Selected_College_Data.Student_Achievements[0]
                                .title
                            }
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="multine-ellipsis"
                          >
                            {
                              Selected_College_Data.Student_Achievements[0]
                                .description
                            }
                          </Typography>
                        </CardContent>
                        <CardActions className="d-flex px-0">
                          {Selected_College_Data.Student_Achievements[0]
                            .avchived_In > 0 &&
                            Selected_College_Data.Student_Achievements[0].avchived_In.map(
                              (items) => {
                                return (
                                  <p className="px-2 py-1 achivemets_data">
                                    {items}
                                  </p>
                                );
                              }
                            )}
                        </CardActions>
                      </CardActionArea>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Grid container>
                        {Selected_College_Data &&
                          Selected_College_Data.Student_Achievements.slice(
                            1,
                            3
                          ).map((items) => {
                            return (
                              <React.Fragment>
                                <Grid xs={12} md={6} className="px-2 my-2">
                                  <CardActionArea>
                                    <CardMedia
                                      component="img"
                                      height="200"
                                      image={
                                        items.image === ""
                                          ? "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
                                          : items.image
                                      }
                                      onError={(e) =>
                                        (e.target.src =
                                          "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                                      }
                                      alt="No Image"
                                      className="card_image_A"
                                      sx={{ objectFit: "cover" }}
                                    />
                                  </CardActionArea>
                                </Grid>
                                <Grid sm={12} md={6}>
                                  <CardActionArea>
                                    <CardContent className="m-0 py-2 px-0">
                                      <Typography
                                        gutterBottom
                                        variant="p"
                                        className="text-primary"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {items.achived_date}
                                      </Typography>
                                      <Typography
                                        className="m-0"
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                      >
                                        {items.title}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        className="multine-ellipsis"
                                      >
                                        {items.description}
                                      </Typography>
                                    </CardContent>
                                    <CardActions className="d-flex px-0">
                                      {items.avchived_In &&
                                        items.avchived_In.map((item) => {
                                          return (
                                            <p className="px-2 py-1 achivemets_data">
                                              {item}
                                            </p>
                                          );
                                        })}
                                    </CardActions>
                                  </CardActionArea>
                                </Grid>
                              </React.Fragment>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
            {Selected_College_Data?.News_Events &&
              Selected_College_Data?.News_Events.length > 0 && (
                <div>
                  <Events
                    Event_DATA={Selected_College_Data?.News_Events}
                    title={Selected_College_Data?.title}
                  />
                </div>
              )}

            {Selected_College_Data?.Subjectdata?.subject.length > 0 && (
              <SubjectList
                subjects={Selected_College_Data.Subjectdata.subject}
              />
            )}
            {/* {Selected_College_Data?.Social_media && (
              <div>
                <CardSocialMedia getdata={PBData} />
              </div>
            )} */}
             
             {College_DATA?.title === "Botany" ? (
        <section className="container d-flex py-5" onClick={handlePlantGridClick}>
          <Grid
            container
            spacing={2}
            className="plant-grid-container"
            sx={{ backgroundColor: "#E2FFE5", borderRadius: "5px" }}
          >
            <Grid item xs={12} sm={4} className="py-5 px-5">
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                {Selected_College_Data?.Plant_Clasifications?.title}
              </Typography>
              <Typography variant="body1">
                Check Out
                <img className="px-2" src={Arrow_icon} alt="Arrow" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              className="py-0"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img src={PlantIcon1} alt="Plant 1" />
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              className="py-0"

              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <img src={PlantIcon2} alt="Plant 2" />
            </Grid>
          </Grid>
        </section>
        ) : null
}
          </div>
        </div>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedPB ? selectedPB.title : ""}</DialogTitle>
        <DialogContent>
          <CardMedia
            component="img"
            height="140"
            image={selectedPB ? selectedPB.image : ""}
            alt="PB Image"
            style={{ width: "100%", height: "340px", objectFit: "cover" }}
          />
          <Typography variant="h5" color="text.secondary">
            {selectedPB ? selectedPB.Name : ""}
          </Typography>
          <CardActions className="d-flex align-items-center justify-content-start">
            <img src={twitterIcon} alt="socialmedia Icon" className="me-3" />
            <img src={linkedinIcon} alt="socialmedia Icon" />
          </CardActions>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Department;
