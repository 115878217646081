import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  InputLabel,
  TableRow,
  Paper,
  FormControl,
} from "@mui/material";
import placedStudents from "../../Assets/JSON/Placementjson/Placements.json";
import placedDropdown from "../../Assets/JSON/Placementjson/Placement_dropdown.json";
import "../../Styles/placements.css";

const PlacementsList = () => {
  const { department } = useParams();
  const [selectedYear, setSelectedYear] = useState("Latest");

 const handleYearChange = (event) => {
  const selectedYear = event.target.value;
  setSelectedYear(selectedYear);
};


  const filteredStudents =
    selectedYear === "Latest"
      ? placedStudents.placedStudents_list[department]
      : placedStudents.placedStudents_list[department].filter(
          (student) => student.academicyear === selectedYear
        );

  return (
    <section className="container py-4">
      <Grid className="studentlist_heading">
        <Typography variant="h4" className="w-100">
          {placedStudents.placedStudents_list.Title}
        </Typography>
        <Grid container justifyContent="right" alignItems="center">
          <FormControl variant="standard" sx={{ mx: 1, my : 2 }}>
          <InputLabel id="demo-simple-select-standard-label">
              Yearwise
            </InputLabel>
            <Select
              value={selectedYear}
              onChange={handleYearChange}
              label="Academic Year"
            >
              <MenuItem value="Latest">Latest</MenuItem>
              {placedDropdown.Academic_Year.map((item) => (
                <MenuItem key={item.ID} value={item.Year}>
                  {item.Year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {filteredStudents.length === 0 ? (
        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
          NO DATA FOUND
        </Typography>
      ) : (
        <TableContainer component={Paper} sx={{ width: "100%" }}>
          <Table aria-label="students table" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell  sx={{ fontWeight: "600" }} >Sr.No</TableCell>
                <TableCell  sx={{ fontWeight: "600" }}>Name of Student</TableCell>
                <TableCell  sx={{ fontWeight: "600" }}>Program</TableCell>
                <TableCell  sx={{ fontWeight: "600" }}>Employer</TableCell>
                <TableCell  sx={{ fontWeight: "600" }}>Year of Graduation</TableCell>
                <TableCell  sx={{ fontWeight: "600" }}>Pay Package</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredStudents.map((student, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>{student.department}</TableCell>
                  <TableCell>{student.employer}</TableCell>
                  <TableCell>{student.graduateyear}</TableCell>
                  <TableCell>{student.pay_package}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </section>
  );
};

export default PlacementsList;
