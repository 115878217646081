import React from 'react'
import NssActivities from '../../Component/Activities_Components/Activities'
import Json_Data from '../../Assets/JSON/ActivitiesJson/Activities.json';

const NSS = () => {
  return (
    <div>
      <NssActivities data={Json_Data.NSSEvents_Activities}  title={"NSS Activities"}/>
    </div>
  )
}

export default NSS