import React, { useState } from "react";
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper,TableRow,TableHead,TableContainer,TableCell,TableBody,Table } from '@mui/material';

const Working_Commitee = () => {
    const [selectedMemberImage, setSelectedMemberImage] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    const workingCommittee = [
        {
            "Sno": 1,
            "id": 1,
            "name": "Dr. M Shekar",
            "designation": "CHAIRMEN, Principal",
            "image": ""
        },
        {
            "Sno": 2,
            "id": 2,
            "name": "Dr.N.Rishikanth",
            "designation": "Director & IQAC-CO-ORDINATOR",
            "image": "url_to_image3.jpg"
        },
        {
            "Sno": 3,
            "id": 3,
            "name": "Mr. K.satyareddy, M.sc, M.tech",
            "designation": "NAAC-CO-ORDINATOR",
            "image": "url_to_image3.jpg"
        },
        {
            "Sno": 4,
            "id": 4,
            "name": "Ms. D. Nitya Angel, Dept Of Mathematics",
            "designation": "Member",
            "image": "url_to_image3.jpg"
        },
        {
            "Sno": 5,
            "id": 5,
            "name": "Mr. M. Shrenik, Dept Of Public Admin",
            "designation": "Member",
            "image": "url_to_image3.jpg"
        },
        {
            "Sno": 6,
            "id": 6,
            "name": "Mr. K.saikiran Goud, Dept Of Computer Science",
            "designation": "Member",
            "image": "url_to_image3.jpg"
        },
        {
            "Sno": 7,
            "id": 7,
            "name": "Mrs. V.kiran Jyothi, Dept Of Library",
            "designation": "Member",
            "image": "url_to_image3.jpg"
        },
        {
            "Sno": 8,
            "id": 8,
            "name": "Ms. B. Anusha, Dept Of Nutrition",
            "designation": "Member",
            "image": "url_to_image3.jpg"
        },
        {
            "Sno": 9,
            "id": 9,
            "name": "Mrs. T. Roopa, Dept Of  Computer Science",
            "designation": "Member",
            "image": "url_to_image3.jpg"
        },
        {
            "Sno": 10,
            "id": 10,
            "name": "Mrs. N. Swapna, Dept Of Management",
            "designation": "Member",
            "image": "url_to_image3.jpg"
        }
    ];
    const IQACMinutes={
        title: "Internal Quality Assurance Cell (IQAC) Minutes of meeting, ATR 2019-2023",
        iqac_data: [
            {
                "title": "IQAC Minutes of meeting, ATR 2019-20",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/IQAC  MINUTES & ACTION TAKEN REPORT-2019-20.pdf"
            },
            {
                "title": "IQAC Minutes of meeting, ATR 2020-21",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/IQAC  MINUTES & ACTION TAKEN REPORT-2020-21.pdf"
            },
            {
                "title": "IQAC Minutes of meeting, ATR 2021-22",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/IQAC  MINUTES & ACTION TAKEN REPORT-2021-22.pdf"
            },
            {
                "title": "IQAC Minutes of meeting, ATR 2022-23",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/IQAC  MINUTES & ACTION TAKEN REPORT-2022-23.pdf"
            },
        ],
    };
    const handleMemberClick = (imageUrl) => {
        setSelectedMemberImage(imageUrl);
        setOpenDialog(true); // Open the dialog
    };

    // Function to close the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <section className="container py-4">
            <div>
                <Typography variant="h5" component="div" sx={{ my: 2 }}>
                    Composition of IQAC
                </Typography>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow >
                            <Typography component={TableCell} variant="h6">S.NO</Typography>
                            <Typography component={TableCell} variant="h6">Name of the Member</Typography>
                            <Typography component={TableCell} variant="h6">Designation</Typography>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workingCommittee.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                onClick={() => handleMemberClick(row.image)} // Pass row.image instead of row.imageUrl
                            >
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell align="">{row.name}</TableCell>
                                <TableCell align="">{row.designation}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="my-4">
            <Typography component="div">
                    <Typography variant="h6">
                        {IQACMinutes.title}
                    </Typography>
                </Typography>
                {IQACMinutes.iqac_data.map(items => {
                    return (
                        <div>
                            <Typography variant="subtitle2" className="cursor-pointer text-decoration" onClick={() => window.open(items.path)}>{items.title}</Typography>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default Working_Commitee;