import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowIconRight from "../../Assets/Icons/Arrow_Small_Right.svg";
import PlantExData from "../../Assets/JSON/Departmentjson/PlantClassification.json";
import "../../Styles/Naac.css";

const NACCExtended = () => {
  const [expanded, setExpanded] = useState(0);
  const [selectedPlantData, setSelectedPlantData] = useState([]);
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const params = useParams();
  const PlantExtended_DATA = PlantExData?.PlantExtended_Headings;
  const PlantSubheadingData = PlantExData.PlantExtended_Data;

  const handleChange = (panel, index) => (event, newExpanded) => {
    localStorage.setItem("expandedIndex", newExpanded ? panel : 1);
    setExpanded(newExpanded ? panel : false);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    color: theme.palette.mode === "dark" ? "#fff" : "black",
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<img src={ArrowIconRight} alt="Arrow Icon" />}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
      backgroundColor: theme.palette.mode === "dark" ? "#ffff" : "#eeee",
      borderRadius: "50%",
    },
  }));

  const filterData = useCallback(
    (selected) => {
      const filteredData = PlantSubheadingData.filter(
        (Plantdata) => Plantdata.ID === selected.ID
      );
      setSelectedPlantData(filteredData);
      const subHeading = selected?.SubHeading ? selected.SubHeading.replace(/\s/g, "-") : selected?.Subheading;
      navigate(`/department/b.sc/botany/plant-details/${selected.ID}/${subHeading}`);
    },
    [PlantSubheadingData, navigate]
  );
  

  useEffect(() => {
    console.log(params.ID);
    if (PlantSubheadingData && PlantSubheadingData.length > 0) {
      const defaultParam = {
        ID: parseInt(params.ID),
        SubHeading: params.SubHeading
      };
      filterData(defaultParam);
    }
  
    return () => {
      isMounted.current = true;
    };
  }, [PlantSubheadingData]);

  return (
    <section className="container py-5">
      <div>
        <Grid container>
          <Grid items xs={12} md={3}>
            {PlantExtended_DATA &&
              PlantExtended_DATA.map((items, parentIndex) => {
                const isExpanded = expanded === parentIndex;
                return (
                  <Accordion
                    key={parentIndex}
                    expanded={isExpanded}
                    onChange={handleChange(parentIndex)}
                  >
                    <AccordionSummary
                      style={{
                        backgroundColor: isExpanded
                          ? "rgb(25, 47, 89)"
                          : "#D0D0D0",
                        color: isExpanded ? "white" : "inherit",
                      }}
                      onClick={() => {
                        if (!isExpanded) {
                          const firstSubheading = items.SubHeading[0];
                          filterData(firstSubheading);
                        }
                      }}
                    >
                      <Typography>{items.Heading}</Typography>
                    </AccordionSummary>
                    {items?.SubHeading &&
                      items.SubHeading.map((subdata, i) => {
                        return (
                          <AccordionDetails
                            key={i}
                            onClick={() => filterData(subdata)}
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "0.9rem",
                                margin: "auto",
                                position: "relative",
                                left: "15px"
                              },
                            }}
                            className={
                              selectedPlantData.some(
                                (data) => data.ID === subdata.ID
                              )
                                ? "selected cursor-pointer"
                                : "cursor-pointer"
                            }
                          >
                            <Typography>{subdata.Subheading}</Typography>
                          </AccordionDetails>
                        );
                      })}
                  </Accordion>
                );
              })}
          </Grid>
          <Grid items xs={12} md={8} className="px-5">
            {selectedPlantData[0]?.URLs ? (
              <div>
                {selectedPlantData.map((HeadItem) =>
                  Object.entries(HeadItem.URLs).map(([key, value]) => (
                    <div key={key}>
                      <div>
                        <Typography component="h4" variant="h5" className="py-4">{value.map((items) => items.Title)}</Typography>
                      </div>
                      <div className="d-flex flex-wrap">
                        {value.map((urldata, index) => (
                          <div key={index} className="col-md-4 mb-2">
                            <div className="w-100">
                            <Typography component="img" sx={{width: "50rem", height:"auto"}} src={urldata.pathurl} alt="Plant-Clasification"/>
                            </div>
                            <div>
                              <Typography component="p" variant="subtitle1">
                                {urldata.filename}
                              </Typography>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            ) : (
              <div>
                <h4>No Data Selected</h4>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <ToastContainer />
    </section>
  );
};

export default NACCExtended;
