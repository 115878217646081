import React from "react";
import {Button, Typography} from '@mui/material';
import CardEvent from "../../Component/Cards/CardEvent";
import CardCareer from "../../Component/Cards/CardCareer";
import Services_Carosel from "../../Component/Carosel/Services_Carosel";
import { Link } from "react-router-dom";


const Events = (Props) => { 
    
    const get_Data = Props.Event_DATA;

    return (
        <section className="container py-5">
            <Typography variant="h4" className="text-center" component="h4">{Props.title}</Typography>
            <CardEvent getdata={get_Data}></CardEvent>
            {/* <CardCareer getdata={PBData}></CardCareer> */}
            {/* <Services_Carosel/> */}
        </section>
    )
}

export default Events